<div class="main-console-container container-fluid drop-shadow p-md-5" >

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      {{niceOtpName}} One-Time Passcode
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">

      <div class="h-100" *ngIf="multiFactor">
        <app-multi-credentials class="h-100" [errorMsg]="errorMsg" [credentialOptions]='otpOptions' buttonLabel='Continue'
                               (selectedCredential)="selectedCredential($event)"></app-multi-credentials>
      </div>

      <form *ngIf="!multiFactor" class="h-100" [formGroup]="verifyOtpForm" novalidate
            [style.visibility]="ready? 'visible' : 'hidden'">

        <!-- First Flex Element -->
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <div>
            <div *ngIf="otpSent">
              <span class="align-middle">Enter security code sent to {{selectedCredValue}}</span>
              <img src="assets/img/help-icon.svg" title="One-time passcode sent via {{niceOtpName}}" alt="Passcode delivery method"
                   class="tooltip-icon"/>
            </div>
            <span class="visible align-middle" *ngIf="!otpSent">{{statusMsg}}</span>
          </div>
        </div>

        <!-- Second Flex Element -->
        <div class="mt-2">
          <div class="row">
            <app-otp-input (otpEntryChange)="onOtpEntryChange($event)"></app-otp-input>
          </div>
        </div>

        <!-- Third Flex Element -->
        <div class="row mt-3">
          <div class="col" *ngIf="resendEnabled">
            <button type="submit" class="primary-btn" (click)="onResendOTP()">
              <fa-icon [icon]="resendIcon" class="pe-2 text-white" title="Resend Icon"></fa-icon>
              Resend
            </button>
          </div>
          <div class="col ms-auto" *ngIf="factorSelection">
            <button class="secondary-btn" (mousedown)="factorSelect()" *ngIf="factorSelection">
              More Options
            </button>
          </div>
        </div>

        <!-- Fourth Flex Element -->
        <div>
          <app-userinfo></app-userinfo>
          <app-loading *ngIf="!ready && !errorDiv"></app-loading>
        </div>

      </form>

    </div>
  </div>
</div>
