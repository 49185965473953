<div class="page-container container-fluid p-0">
  <!--Header-->
  <nav class="navbar header-format">
    <div class="navbar-brand header-banner pointerOnHover px-2" routerLink="/">
      <img src="./assets/img/bnymLogo.png" class="header-logo" alt="BNYM Logo" />
      <span class="ah-home">Authentication Hub</span>
    </div>
    <div class="pe-2 me-md-4" *ngIf="isAuthenticated">
      <div class="logout-btn pointerOnHover rounded-3 mb-0" (click)="doLogout()">
        <div>
          <fa-icon [icon]="logoutButton" class="p-2" title="Logout"></fa-icon>
        </div>
      </div>
    </div>
  </nav>

  <!--Content Body-->
  <div class="component-container px-4 px-md-5 py-3 py-lg-5">
    <!-- Embed Page Contents -->
    <router-outlet></router-outlet>
  </div>

  <!-- Footer-->
  <footer class="ah-footer container-fluid py-3">
    <div>
      <ul id="footer-links">
        <li><a href="https://www.bnymellon.com/us/en/data-privacy.html">Privacy Statement</a></li>
        <li><a class="pointerOnHover" routerLink="/cookies">Cookie Policy</a></li>
        <li><a href="https://www.bnymellon.com/us/en/terms-of-use.html">Terms of Use</a></li>
        <li><a class="pointerOnHover" routerLink="/sms-terms-of-service">SMS Terms of Service</a></li>
        <li><a class="pointerOnHover" routerLink="/help">Login Assistance</a></li>
      </ul>
    </div>
    <div class="bnym-copyright pt-2">© 2022 The Bank of New York Mellon Corporation.</div>
  </footer>

</div>
