<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row mb-3">
    <!-- Title -->
    <div class="col-12 g-0 p-md-2" [ngClass]="{'col-md-12': !hasValues, 'col-md-6': hasValues}">
      <div class="main-console-title h-100">
        Login Assistance
      </div>
    </div>

    <!-- Card 1 -->
    <div class="multi-cards p-0 col-12 col-md-6 p-md-2" *ngIf="hasValues">
      <div class="main-console-card p-3 p-md-4 h-100">
        <h5>Troubleshoot Information</h5>
        <div class="row mb-2" *ngIf="hasValues">
          <div class="col">
            If you need assistance to log in, copy the following information and send it to the help desk:
          </div>
        </div>
        <div class="flex-space justify-content-around p-3 h-50">
          <div class="row" *ngIf="lastTransaction">
            <div class="col">
              <h6>Timestamp: </h6>{{lastTransaction}}
            </div>
          </div>
          <div class="row" *ngIf="clientTxId">
            <div class="col">
              <h6>Client Transaction: </h6>{{clientTxId}}
            </div>
          </div>
          <div class="row" *ngIf="userName">
            <div class="col">
              <h6>User: </h6>{{userName}}
            </div>
          </div>
          <div class="row" *ngIf="!hasValues">
            <div class="col">
              <i>No troubleshoot information to display at this time.</i>
            </div>
          </div>
        </div>

        <button class="secondary-btn mb-0" (click)="onCopyToClipboard()" *ngIf="hasValues">
          Copy to Clipboard
        </button>
      </div>
    </div>


    <!--  Row 2 - Contact Info-->
    <!-- Card 2 -->
    <div class="multi-cards p-0 col-12 col-md-6 p-md-2">
      <div class="main-console-card p-3 p-md-4">
        <h5>Clients</h5>
        <span>
          <h6>Hours of Operation: </h6>SUN 8:00 p.m. through FRI 8:00 p.m. Eastern Time
        </span>

        <div class="row mx-0 mx-md-3 my-3">
          <div class="row">
            <div class="col">
              <strong>Region</strong>
            </div>
            <div class="col">
              <strong><h6>Phone Number</h6></strong>
            </div>
          </div>

          <div class="row" *ngFor="let contacts of clientPhoneMap | keyvalue">
            <div class="col">
              <h6>{{contacts.key}}</h6>
            </div>
            <div class="col ms-auto">
              {{contacts.value}}
            </div>
          </div>
        </div>

        <span>
          <h6>Email: </h6><a href="mailto:support@bnymellon.com">support@bnymellon.com</a>
          <p>(Only to be used for unlocking an account.)</p>
        </span>
      </div>
    </div>


    <!-- Card 3 -->
    <div class="multi-cards p-0 col-12 col-md-6 p-md-2">
      <div class="main-console-card do-not-flex h-100 p-3 p-md-4">
        <h5>Employees</h5>

        <span>
          <h6>Hours of Operation: </h6>24 hours a day, 7 days a week
        </span>

        <div class="row mx-0 mx-md-3 mt-3">
          <div class="row">
            <div class="col">
              <strong>Region</strong>
            </div>
            <div class="col">
              <strong>Phone Number</strong>
            </div>
          </div>
          <div class="row" *ngFor="let contacts of internalPhoneMap | keyvalue">
            <div class="col">
              <h6>{{contacts.key}}</h6>
            </div>
            <div class="col ms-auto">
              {{contacts.value}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
