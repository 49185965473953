import {Component, OnDestroy, OnInit} from '@angular/core';
import {FactorselectionService} from "../service/factorselection.service";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors} from "@angular/forms";
import {Router} from "@angular/router";
import {DisplayService} from "../service/display.service";
import {YmaService} from "../service/yma.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-securid',
  templateUrl: './securid.component.html'
})
export class SecuridComponent implements OnInit, OnDestroy {

  errorMsg: String;
  errorDiv: boolean = false;
  displayCodeError: boolean = false;
  userName = null;
  securidForm: UntypedFormGroup;
  factorSelection: boolean = false;
  inlineMessage: string;
  ready: boolean = true;
  otpEntry: string;
  validEntry: boolean;
  pageSubs: Subscription[] = new Array<Subscription>();

  constructor(private factorselectionService: FactorselectionService,
              private formBuilder: UntypedFormBuilder,
              private ymaService: YmaService,
              private router: Router,
              private displayService: DisplayService) { }

  ngOnInit(): void {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }
    this.inlineMessage = this.displayService.inlineMessage || "";
    this.securidForm = this.formBuilder.group({
      code: ['', validatorSecuridCode]
    });
    this.factorSelection = this.displayService.factorSelection;
    this.displayService.focusNthField();
  }

  onCodeSubmit() {
    this.ready = false;
    this.errorDiv = false;
    this.errorMsg = null;
    this.pageSubs = [
      this.ymaService.onSecurIDLogin(this.otpEntry).subscribe({
        next: (data) => {
          this.displayService.routeActions(data);
        },
        error: (err) => {
          this.ready = true;
          this.errorDiv = true;
          let status = err.error?.data?.status;
          if (status === "Reject") {
            this.errorMsg = "Invalid pin+code entered";
          } else if (status === "Terminate") {
            this.router.navigate(['denied']);
          } else {
            this.errorMsg = "System error";
          }
        }
      })
    ];
  }

  factorSelect() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  onOtpEntryChange(event) {
    this.otpEntry = event.otpInput;
    if (event.submit) {
      this.onCodeSubmit()
    }
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}

function validatorSecuridCode(c: AbstractControl): ValidationErrors | null {
  if(!c.value) {
    return {'required': true};
  }
  else if(c.value && (!c.value.match("\\d{10}") || c.value.length != 10)) {
    return {'invalidSecuridCode': true};
  }

}
