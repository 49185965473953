import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DisplayService } from '../service/display.service';
import { GlobalService } from '../service/global.service';
import { PushService } from '../service/push.service';
import {FactorselectionService} from "../service/factorselection.service";
import {Observable, Subscription} from "rxjs";
import {faMobileAlt, faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {AuthStateService} from "../service/auth-state.service";

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.scss']
})
export class PushComponent implements OnInit, OnDestroy {
  pushOptions: string[] = [];
  credId = '';
  errorDiv: boolean = false;
  errorMsg: String;
  selectPush: boolean = false;
  counter: number;
  factorSelection: boolean = false;
  elementType: 'url' | 'canvas' | 'img' = 'url';
  value: string = '';
  selectedCredValue: string;
  timerId: any;
  credentialId: string;
  multifactor: boolean = false;
  ready: boolean;
  pageSubs: Subscription[] = new Array<Subscription>();
  pushStatusMsg: any;
  phoneIcon = faMobileAlt;
  statusHandler$: Observable<Object>;
  resendIcon = faSyncAlt;
  timeoutErr: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private displayService: DisplayService,
              public pushService: PushService,
              public globalService: GlobalService,
              private authStateService: AuthStateService,
              private factorselectionService: FactorselectionService) { }

  ngOnInit() {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }
    this.factorSelection = this.displayService.factorSelection;
    if (this.displayService.action === "PUSH_SELECTION") {
      this.selectPush = this.displayService.selectPush;
    }
    this.pushOptions = this.displayService.pushOptions;
    this.pushOptions.forEach(function (value) {
      if (value["default"] === true) {
        this.credId = value["credId"];
        this.selectedCredValue = value["credValue"];
      }
    }.bind(this));
    if (this.pushOptions.length === 1) {
      this.credId = (<any>this.pushOptions[0]).credId;
      this.selectedCredValue = (<any>this.pushOptions[0]).credValue;
      if (!this.globalService.isRememberDeviceEnabled()) {
        this.onGenerate();
      }
    }
    else {
      this.multifactor = true;
    }
    this.displayService.focusNthField();
  }

  selectedCredential(event: any) {
    this.multifactor = false;

    if (event) {
      this.credId = event.credId;
      this.selectedCredValue = event.credValue;
    }
    this.selectPush = false;
    if (!this.globalService.isRememberDeviceEnabled()) {
      this.onGenerate();
    }
  }

  onGenerate() {
    this.pushStatusMsg = "Sending push notification . . .";
    this.ready = false;
    this.timeoutErr = false;
    this.errorDiv = false;
    this.counter = 0;
    this.pushService.onGenerate(this.credId)
      .subscribe({
        next: (data) => {
          this.displayService.updateCurrentFactorLevel(data);
          if (data["nextaction"] === "PUSH_AUTH") {
            this.errorMsg = "";
            this.errorDiv = false
            this.selectPush = false;
            this.ready = true;
            this.pushStatusMsg = "";
            this.displayService.flowState = data["flowState"];
            this.verifyPush();
          }
        },
        error: (err) => {
          this.errorDiv = this.displayService.errorDiv;
          this.setError(this.displayService.errorMsg);
        }
      });
  }

  verifyPush() {

    this.statusHandler$ = this.pushService.getVerifyObservable(this.credId);

    this.pageSubs.push(
      this.statusHandler$
        .subscribe({
          next: (data) => {
            // Determine state of response, and continue or throw error
            if (data?.['data']?.["status"] === "AUTHENTICATED") {
              this.ready = false;
              this.displayService.routeActions(data);
            } else if (data?.['data']?.["status"] === "REJECTED") {
              this.setError("Transaction denied");
            } else if (data?.["errorCode"]) {
              this.setError("Transaction failed");
            } else if (data?.["data"]?.["status"] == "TIMED_OUT") {
              this.errorDiv = true;
              this.errorMsg = "Transaction timed out";
              this.timeoutErr = true;
              this.ready = true;
            }
          },
          error: (err) => this.setError("Transaction failed unexpectedly")
        })
    );
  }

  setError(errMsg) {
    /*
      Only timeout error is recoverable. If any other error occurs, then the x-flow-state will be
      invalidated. Thus, set the appropriate auth flow state.
    */
    this.authStateService.setAuthStatusRestartNeeded();
    this.errorDiv = true;
    this.displayService.errorMsg = errMsg;
    this.ready = true;
  }

  chooseAnother() {
    this.pushStatusMsg = "";
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
