<h2 mat-dialog-title class="nxn-header-separator">Confirm Changes</h2>
<mat-dialog-content>
  {{data.message}}
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button *ngIf="data.btnYes" name="yesBtn" class="dialog-primary-btn" cdkFocusInitial (click)="onYes()">Yes</button>

  <button mat-button *ngIf="data.btnSave" name="saveBtn" class="dialog-primary-btn" cdkFocusInitial (click)="onSave()">Save</button>
  <button mat-button *ngIf="data.btnDiscard" name="discardBtn" class="dialog-secondary-btn" (click)="onDiscard()">Discard</button>
  <button mat-button name="cancelBtn" class="dialog-secondary-btn" (click)="onCancel()">Cancel</button>

</mat-dialog-actions>
