<div class="main-console-container drop-shadow container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      A Better Login Experience
    </div>

    <div class="main-console-card flex-space col-md-6 text-justify ms-auto">
      <p>Single Sign On for BNY Mellon Applications.</p>
      <p>Sign-in faster and more securely. No password needed.</p>

      <form class="do-not-flex" novalidate>
        <button id="login-btn" type="login" class="primary-btn align-bottom container-fluid" routerLink="/direct">
          <img src="assets/img/favicon.png" class="white-bnym-logo" />
          Login with BNY Mellon
        </button>
      </form>

      <div class="text-center text-muted mt-2">
        <i>
          For more details visit our <a class="reference" routerLink="/aboutAuthHub">information page</a>.
        </i>
      </div>
    </div>
  </div>
</div>
