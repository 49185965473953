<h2 mat-dialog-title class="nxn-header-separator">Register your Credential</h2>
<mat-dialog-content>

  <div class="row text-center">
    <div class="col-12 col-xl-12 ">
      <div class="input-text" >Enter the {{data.credType.toUpperCase()}} OTP that was sent to your device</div>
      <input [formControl]="otpValueControl" type="numeric" class="form-control user-input mb-1" placeholder="Security Code"
           (keydown.enter)="onVerifyOTP()">
    </div>
  </div>

  <div class="row text-center" *ngIf = "errorMsg">
    <div class="col-12 col-xl-12">
      <span class="alert-red">{{errorMsg}}</span>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button name="verifyBtn" class="registerBtnLeft dialog-primary-btn" cdkFocusInitial (click)="onVerifyOTP()" [disabled]="otpValueControl.invalid || submitted">Verify Code</button>
    <button mat-button name="cancelBtn" class="registerBtnRight dialog-secondary-btn" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>





