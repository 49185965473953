import {HttpClient} from '@angular/common/http';
import * as Fingerprint2 from "fingerprintjs2";
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { DisplayService } from './display.service';
import {CookieService} from "./cookie.service";
import {switchMap} from "rxjs/operators";
import {BrandingService} from "./branding.service";
import {GlobalService} from "./global.service";

declare function encode_deviceprint(): any;

@Injectable({
  providedIn: 'root'
})
export class InitService {

  loginEnabled: boolean = false;
  settingsMsg: boolean = false;
  errorMsg = "";
  fingerPrintDna: string = null;
  iaFingerPrint: string;
  defaultFingerPrint: string;
  serviceUrl: string;
  riskTypeList: any = ['IARISK', 'AARISK', 'RSARISK']
  riskType: string = "RSARISK";

  constructor(
    private http: HttpClient,
    private displayService: DisplayService,
    private cookieService: CookieService,
    private globalService: GlobalService,
    private brandingService: BrandingService
  ) {
    // Get IA fingerprint
    this.iaFingerPrint = window["IaDfp"] && window["IaDfp"].readFingerprint();

    // Set default fingerprint type
    const self = this;
    setTimeout(function () {
      const options = {};
      Fingerprint2.getPromise(options).then(function (components) {
        const object = {};
        for (const index in components) {
          const obj = components[index];
          object[obj.key] = obj.value;
        }
        // If the fingerprint was already set, do not override
        if (!self.fingerPrintDna) {
          self.fingerPrintDna = JSON.parse(JSON.stringify(object));
          self.setDevicePrint();
        }
      });
    }, 500);
  }

  public onUserNameLogin(postData): Observable<any> {
    let userName = (postData["subject"] || "").toLowerCase();
    if (userName) {
      let tdiValFromCookie = this.cookieService.getCookie("tdi-" + this.displayService.obfuscate("SSP-TDI-" + userName));
      if (tdiValFromCookie) {
        postData['trustedDeviceID'] = tdiValFromCookie;
      }
      let fidoID = this.cookieService.getCookie("fid-" + this.displayService.obfuscate("ssp-fido-identifier-" + this.displayService.obfuscate(userName)));
      if (fidoID) {
        let authContext = [];
        authContext.push({key: "fidoIdentifier", value: fidoID});
        postData['authContext'] = authContext;
      }
    }

    return this.http.post(this.displayService.serviceUrl + 'auth/v1/authenticate', postData);
  }

  /**
   * Configure the authentication parameters according to the risk type
   * @param userName
   * @param rememberMe
   */
  getAuthenticatePostData(userName: string, rememberMe: boolean): any {
    // Ensure deviceprint is set in display service
    this.setDevicePrint();

    const data = {
      channel: 'web',
      action: 'authenticate'
    };
    if (localStorage.getItem("riskType") === "IARISK") {
      data['device'] = {signature: {"iaAuthData": this.displayService.fingerPrintDna}};
    } else if (localStorage.getItem("riskType") === "AARISK") {
      data['device'] = {
        signature: this.displayService.fingerPrintDna
      };
      if (this.cookieService.getCookie("ssp-aa")) {
        data["device"]["id"] = this.cookieService.getCookie("ssp-aa");
      }
    } else if (localStorage.getItem("riskType") === "RSARISK") {
      data['clientContext'] = [
        {
          key: "deviceprint",
          value: this.displayService.fingerPrintDna
        },
        {
          key: "rememberme",
          value: rememberMe
        }
      ];
      let devicetoken = localStorage.getItem('rsadevicetoken');
      if (devicetoken) {
        data['clientContext'].push({
          key: "devicetoken",
          value: devicetoken
        });
      }
    } else {
      data['device'] = {signature: this.displayService.fingerPrintDna};
    }
    if (rememberMe) {
      data['rememberMe'] = true;
    }
    if (userName) {
      data['subject'] = userName;
    }

    /* This is needed, for oauth2 types of authentication */
    if(this.displayService.flowState) {
      data['LATEST-FLOW-STATE'] = '[LATEST-FLOW-STATE]';
    }
    return data;
  }

  /**
   * Set the device print according to the risk type
   */
  setDevicePrint(): any {
    // IA and RSA risk use unique fingerprints
    if (localStorage.getItem("riskType") === "IARISK") {
      this.fingerPrintDna = this.iaFingerPrint;
    } else if (localStorage.getItem("riskType") === "RSARISK") {
      this.fingerPrintDna = encode_deviceprint();
    }
    // Propagate fingerprint to display service
    this.displayService.fingerPrintDna = this.fingerPrintDna;
  }

  decodeToken(token) {
    if (!token) {
      return [];
    }
    const sJWT = token.split(".");
    const uHeader = window['b64utos'](sJWT[0]);
    const uClaim = window['b64utos'](sJWT[1]);
    const pHeader = window['KJUR'].jws.JWS.readSafeJSONString(uHeader);
    const pClaim = window['KJUR'].jws.JWS.readSafeJSONString(uClaim);
    return [pHeader, pClaim];
  }

  /**
   * Updates the risk type in multiple places. Does nothing if the parameter is not one of the expected risk types.
   * @private
   */
  public changeRiskType(newRiskType: string) {
    // Update risk type if valid
    if (this.riskTypeList.find(rType => rType === newRiskType)) {
      this.riskType = newRiskType;
    }
    // Propagate risk type
    this.displayService.riskType = this.riskType;
    localStorage.setItem("riskType", this.displayService.riskType);
    // Update fingerprint appropriately
    this.setDevicePrint();
  }

  checkIdPropagation(): Observable<any | void> {
    if (window.location.href.indexOf("restart=true") === -1 && this.displayService.serviceUrl && this.displayService.flowState) {
      // Search for username via BrandingSettings/ API
      return this.brandingService.loadSettings(this.displayService.serviceUrl);
    }
  }

  authenticate(userName: string, rememberMe: boolean): Observable<any> {
    this.globalService.setRememberDeviceEnabled(false);
    let data = this.getAuthenticatePostData(userName, rememberMe);

    return this.onUserNameLogin(data);
  }
}
