<h2 mat-dialog-title class="nxn-header-separator">Login Assistance Information</h2>
<mat-dialog-content class="container flex-space">
  <div class="row">
    <div class="col">
      If you need assistance to log in, copy the following information and send it to the help desk:
    </div>
  </div>
  <div class="row">
    <div class="col">
      <strong>Timestamp:</strong> {{lastTransaction}}
    </div>
  </div>
  <div class="row">
    <div class="col">
      <strong>Client Transaction:</strong> {{clientTxId}}
    </div>
  </div>
  <div class="row">
    <div class="col">
      <strong>User:</strong> {{userName}}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button name="cancelBtn" class="dialog-primary-btn" cdkFocusInitial [disabled]="btnClipboardDisabled" (click)="onCopy()">Copy to Clipboard</button>
  <button mat-button name="cancelBtn" class="dialog-secondary-btn" (click)="onClose()" style="float: right;">Close</button>
</mat-dialog-actions>
