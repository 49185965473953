<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">

    <div class="main-console-title mx-auto mb-md-3">
      Access Denied
    </div>

    <div class="main-console-card text-justify mx-auto">
      If you have legitimate need to access this system, please use the information from links below to resolve.
      <div class="row my-3">
        <div class="col-md-6 mb-2 m-md-0">
          <a routerLink="/help" class="reference">Login Assistance</a>
        </div>
      </div>
    </div>

  </div>

</div>
