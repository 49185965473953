import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { decode, encode } from '../shared/base64';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog.component';
import { DisplayService } from '../service/display.service';
import { FidoregService } from '../service/fidoreg.service';
import { SelfService } from '../service/self.service';
import { CookieService } from "../service/cookie.service";
import { PushService } from '../service/push.service';
import {RegisterOAthComponent} from "./register-oath/register-oath-component";
import {RegisterEmailOrSmsComponent} from "./register-email-sms/register-email-sms-component"
import {faTrashAlt, faHomeAlt, faSyncAlt, faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html'
})
export class SelfServiceComponent implements OnInit, OnDestroy {
  credentialsList = [];
  statuses = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' }
  ];
  statusMap = {};
  credTypeMap = {
    "sms": { name: "SMS", rank: 1, label: "phone number starting with country code" },
    "email": { name: "Email", rank: 2, label: "email address" },
    "fido": { name: "Biometric", rank: 3, label: "friendly name" },
    "securitykey": { name: "Security key", rank: 4, label: "key name" },
    "totp": { name: "Mobile OTP", rank: 5, label: "friendly name" },
    "totp_push": { name: "VIP Push and OTP", rank: 6, label: "friendly name" }
  };
  private months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  selectedCredType = 'email';
  credValueControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  vipCredentialControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  regBtnClicked = false;
  errorMessage: string;
  showError: boolean = false;
  isLoading: boolean;
  waitingForPush: boolean = false;
  waitingForBio: boolean = false;
  totpSharedSecrets = {};
  deleteIcon = faTrashAlt;
  refreshIcon = faSyncAlt;
  homeIcon = faHomeAlt;
  infoIcon = faCircleInfo;
  pageSubs: Subscription[] = new Array<Subscription>();


  constructor(
    private selfService: SelfService,
    private router: Router,
    public displayService: DisplayService,
    private fidoregService: FidoregService,
    private cookieService: CookieService,
    private pushService: PushService,
    private confirmDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.statuses.forEach((status) => {
      this.statusMap[status.value] = "Y";
    });

    this.isLoading = true;

    this.getCreds();
  }

  getCreds(newCredId?: string) {
    this.pageSubs.push(
      this.selfService.getCredentials().subscribe({
        next: (res: any) => {
          let finalArray = res;
          finalArray.sort((c1, c2) => {
            let rank1 = (this.credTypeMap[c1.credType] || {}).rank;
            let rank2 = (this.credTypeMap[c2.credType] || {}).rank;
            if (rank1 < rank2) {
              return -1;
            } else if (rank1 > rank2) {
              return 1;
            } else {
              return 0;
            }
          });
          this.credentialsList = finalArray;
          this.isLoading = false;
          this.regBtnClicked = false;
          let container = document.querySelector(".js-selfservice-wrapper");
          container && (container.scrollTop = 0);
          setTimeout(() => {
            let verifyOTPInput = document.querySelector("tr[data-cred-id=\"" + newCredId + "\"] .js-verifyCredOTPInput");
            verifyOTPInput && verifyOTPInput["focus"]();
          }, 10);
        },
        error: (err) => {
          this.isLoading = false;
          this.showErrorMessage(err.error);
          this.regBtnClicked = false;
        }
      })
    );
  }

  getCustomDateFormat(dateStr) {
    let dt;
    try {
      dt = new Date(dateStr);
      if (isNaN(dt.getTime()) || !dt.getTime()) {
        throw new Error("Invalid Date String");
      }
      let res = "";
      res += this.months[dt.getMonth()] + " " + dt.getDate() + ", " + dt.getFullYear();
      return res;
    } catch (exjs) {
      return dateStr;
    }
  }

  handleOTPInput(e, verifyOTPBtn, errorElem) {
    if (e && e.target) {
      if (e.target.value && e.target.value.trim()) {
        verifyOTPBtn && (verifyOTPBtn["disabled"] = false);
      } else {
        verifyOTPBtn && (verifyOTPBtn["disabled"] = true);
      }
    }
    errorElem && (errorElem.innerHTML = "");
  }

  onStatusChange(credItem, status) {
    this.updateCredStatus(credItem.credId, status, credItem.default);
  }

  updateCredStatus(id, value, isDefault) {
    this.pageSubs.push(
      this.selfService.statusUpdate(id, value, isDefault).subscribe({
        next: (res) => {
          this.getCreds();
        },
        error: (err) => {
          const index = this.credentialsList.findIndex((item) => item.credId === id);
          if (index !== -1) {
            // For reseting Status dropdown
            const statusCopy = this.credentialsList[index].status;
            this.credentialsList[index].status = null;
            setTimeout(() => {
              this.credentialsList[index].status = statusCopy;
            }, 1);
          }
          this.showErrorMessage(err.error);
        }
      })
    );
  }

  handleDeleteCred(creditem) {
    let credTypeLabel = this.credTypeMap[creditem.credType].name;
    let dc: MatDialogConfig = {
      data: {
        message: `Do you want to delete the credential of type ${credTypeLabel}?`,
        btnYes: true
      }
    }
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, dc);
    this.pageSubs.push(
      dialogRef.afterClosed().subscribe( result => {
        switch(result.response) {
          case 'yes':
            this.deleteCred(creditem);
            break
          default:
        }
      })
    );
  }

  private deleteCred(credItem) {
    this.selfService.deleteCredential(credItem.credId).subscribe({
      next: (res) => {
        this.getCreds();
      },
      error: (err) => {
        this.showErrorMessage(err.error);
      }
    })
  }

  handleDeleteAll() {
    let dc: MatDialogConfig = {
      data: {
        message: `All your credentials will be deleted. Are you sure?`,
        btnYes: true
      }
    }
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, dc);
    dialogRef.afterClosed().subscribe( result => {
      switch(result.response) {
        case 'yes':
          this.deleteAll();
          break
        default:
      }
    })
  }

  private deleteAll() {
    let credCount = this.credentialsList.length;
    let ctr = 0;
    this.credentialsList.map((item) => {
      this.selfService.deleteCredential(item.credId).subscribe({
        next: (res) => {
          ctr++;
          if (ctr === credCount) {
            this.getCreds();
          }
        },
        error: (err) => {
          this.showErrorMessage(err.error);
          this.getCreds();
        }
      })
    })
  }

  onRegister() {
    this.errorMessage = "";
    if (this.selectedCredType) {
      if (this.selectedCredType === 'sms') {
        let processedPhoneNumber = this.credValueControl.value.replace(/[^0-9]+/g, '');
        this.credValueControl.setValue(processedPhoneNumber);
      }
      if (this.credValueControl.valid) {
        if (this.selectedCredType === "fido" || this.selectedCredType === "securitykey") {
          this.registerFIDOorSecurityKey();
          return;
        }
        if (this.selectedCredType === "totp_push") {
          this.registerVIPAccessPush();
          return;
        }
        if (this.selectedCredType === "totp") {
          this.registerMobileOTPAuthenticator();
          return;
        }
        let inputValue: string = this.credValueControl.value.trim();

        this.regBtnClicked = true;
        this.selfService.credentialRegister(this.selectedCredType, inputValue).subscribe({
          next: (res) => {
            this.credValueControl.reset();
            this.getCreds(res["credId"]);
            if (this.selectedCredType === 'sms' || this.selectedCredType === 'email') {
              this.registerEmailOrSmsCredentials(res);
            }
          },
          error: (err) => {
            this.showErrorMessage(err.error);
            this.regBtnClicked = false;
          }
        })
      } else if (this.selectedCredType === 'sms') {
        let error = {
          errorMessage: 'Phone number should start with your country code \n (US code is 1)'
        }
        this.showErrorMessage(error);
      }
    }
  }

  registerVIPAccessPush() {
    this.waitingForPush = true;
    let credName = this.credValueControl.value.trim();
    let vipCred = this.vipCredentialControl.value.trim().replace(/\s/g, "");
    this.pushService.registerVipPush(this.selectedCredType, credName, vipCred).subscribe({
      next: (response) => {
        this.credValueControl.reset();
        this.vipCredentialControl.reset();
        this.getCreds(response["credId"]);
        this.verifyPush(response["credId"]);
      },
      error: (err) => {
        this.waitingForPush = false;
        this.regBtnClicked = false;
        this.showErrorMessage(err.error);
      }
    });
  }

  verifyPush(credId) {
    const statusHandler$ = this.pushService.getVerifyObservable(credId);

    this.pageSubs.push(
      statusHandler$.subscribe({
        next: (response) => {
          if (response?.["status"] === "AUTHENTICATED") {
            this.getCreds(credId);
            this.waitingForPush = false;
          } else if (response?.["status"] === "REJECTED") {
            this.regBtnClicked = false;
            this.showErrorMessage("Transaction denied");
            this.waitingForPush = false;
          } else if (response?.["errorCode"]) {
            this.showErrorMessage("Transaction failed");
            this.waitingForPush = false;
          } else if (response?.["status"] == "TIMED_OUT") {
            this.showErrorMessage("Transaction timed out");
            this.waitingForPush = false;
          }
        },
        error: (err) => {
          this.regBtnClicked = false;
          this.showErrorMessage(err.error);
          this.waitingForPush = false;
        }
      })
    );
  }

  registerFIDOorSecurityKey() {
    if (this.selectedCredType === "fido") {
      this.displayService.fidoRegCredType = "FIDO";
      this.displayService.fidoRegScreenMode = "FIDO";
    } else if (this.selectedCredType === "securitykey") {
      this.displayService.fidoRegCredType = "SECURITYKEY";
      this.displayService.fidoRegScreenMode = "SECURITYKEY";
    }
    let inputValue: string = this.credValueControl.value.trim();
    this.regBtnClicked = true;
    this.waitingForBio = true;
    this.fidoregService.activationGenerateChallenge(this.displayService.userName, inputValue, this.displayService.fidoRegCredType)
      .subscribe((response) => {
        if (sessionStorage.getItem("__authn_debug000")) {
          let option = sessionStorage.getItem("__authn_debug000");
          if (("" + option) === ("" + window["RSWF"])) {
            response["authenticatorSelection"]["authenticatorAttachment"] = "platform";
          } else if (("" + option) === ("" + window["RFWS"])) {
            response["authenticatorSelection"]["authenticatorAttachment"] = "cross-platform";
          }
        }
        const publicKey1 = this.preformatMakeCredReq(response);
        return navigator.credentials.create({ publicKey: publicKey1 }).then((response) => {
          const makeCredResponse = this.publicKeyCredentialToJSON(response);
          return this.sendRegistrationVerifyChallenge(makeCredResponse);
        }, (err) => {
          this.regBtnClicked = false;
          this.waitingForBio = false;
          this.showErrorMessage(err);
        }).catch((err) => {
          this.regBtnClicked = false;
          this.waitingForBio = false;
          this.showErrorMessage(err);
        });
      }, (err) => {
        this.regBtnClicked = false;
        this.waitingForBio = false;
        this.showErrorMessage(err.error);
      });
  }

  preformatMakeCredReq = (makeCredReq) => {
    makeCredReq.challenge = decode(makeCredReq.challenge);
    makeCredReq.user.id = decode(makeCredReq.user.id);
    if (makeCredReq.excludeCredentials && makeCredReq.excludeCredentials.length > 0) {
      for (let i = 0; i < makeCredReq.excludeCredentials.length; i++) {
        makeCredReq.excludeCredentials[i].id = decode(makeCredReq.excludeCredentials[i].id);
      }
    }
    return makeCredReq;
  }

  publicKeyCredentialToJSON = (pubKeyCred) => {
    if (pubKeyCred instanceof Array) {
      const arr = [];
      for (const i of pubKeyCred) {
        arr.push(this.publicKeyCredentialToJSON(i));
      }
      return arr;
    }
    if (pubKeyCred instanceof ArrayBuffer) {
      encode(pubKeyCred);
      return encode(pubKeyCred);
    }
    if (pubKeyCred instanceof Object) {
      const obj = {};
      for (const key in pubKeyCred) {
        obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key]);
      }
      return obj;
    }
    return pubKeyCred;
  }

  sendRegistrationVerifyChallenge(makeCredResponse) {
    this.fidoregService.sendRegistrationVerifyChallenge(makeCredResponse, this.displayService.fidoRegCredType).subscribe((response) => {
      let fidoID = (response["data"] && response["data"]["fidoIdentifier"]) || response["fidoIdentifier"];
      if (fidoID) {
        let expiryDays = parseInt(((response["data"] && response["data"]["fidoIdentifierExpiryDays"]) || response["fidoIdentifierExpiryDays"] || "365"), 10);
        let expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
        this.cookieService.setCookie("fid-" + this.displayService.obfuscate("ssp-fido-identifier-" + this.displayService.obfuscate(this.displayService.userName.toLowerCase())), fidoID, expiryDate);
      }
      this.credValueControl.reset();
      this.waitingForBio = false;
      this.getCreds();
    }, (err) => {
      this.regBtnClicked = false;
      this.waitingForBio = false;
      this.showErrorMessage(err.error);
    });
  }

  credTypeChange() {
    if (("" + this.selectedCredType).toLowerCase() === 'email') {
      this.credValueControl = new UntypedFormControl('', [Validators.required, Validators.email]);
    } else if (("" + this.selectedCredType).toLowerCase() === 'sms') {
      this.credValueControl = new UntypedFormControl('', [Validators.required, Validators.minLength(11)]);
    } else if (("" + this.selectedCredType).toLowerCase() === 'fido') {
      this.credValueControl = new UntypedFormControl('', [Validators.required]);
    } else if (("" + this.selectedCredType).toLowerCase() === 'securitykey') {
      this.credValueControl = new UntypedFormControl('', [Validators.required]);
    } else if (("" + this.selectedCredType).toLowerCase() === 'totp_push') {
      this.credValueControl = new UntypedFormControl('', [Validators.required]);
    } else if (("" + this.selectedCredType).toLowerCase() === 'totp') {
      this.credValueControl = new UntypedFormControl('', [Validators.required]);
    }
    this.errorMessage = '';
  }

  focusRegInputField() {
    const inputEle = document.querySelector('.register-container .input-container input[type="text"]');
    inputEle && inputEle['focus']();
  }

  showErrorMessage(error) {
    let errorObject = error || {};
    this.errorMessage = errorObject.errorMessage || "Unable to register, please try again.";
    this.showError = true;
  }

  private registerMobileOTPAuthenticator() {
    // Call the MOTP service to obtain shared secret
    let deviceName = this.credValueControl.value;
    this.selfService.registerMobileOtp('TOTP', deviceName)
      .subscribe({
          next: (data) => {
            let totpCredId = data["credId"];
            let userName = this.displayService.userName;
            let tenantName = data["tenantName"];
            let issuer = tenantName;
            if (tenantName==="default" || tenantName==="common") {
              let hostComponents = window.location.hostname.split(".");
              if (hostComponents[1]==="dev" ||
                hostComponents[1]==="test" ||
                hostComponents[1]==="qa") {
                issuer = "BNYM " + hostComponents[1].toUpperCase();
              } else if(hostComponents[1]==="bnymellon") {
                issuer = "BNY Mellon";
              } else {
                issuer = tenantName;
              }
            }
            let sharedSecret = "otpauth://totp/" + userName;
            sharedSecret += "?secret=" + data["sharedSecret"] + "&issuer=" + issuer;
            this.totpSharedSecrets[totpCredId] = sharedSecret;
            this.credValueControl.reset();
            this.registerOAth(data);
          },
          error: (err) => {
            this.showErrorMessage(err.error);
          }
        }
      );
  }

  registerOAth(item: any) {
    let dc: MatDialogConfig = {
      data: {
        credId: item.credId,
        credName: item.credValue,
        sharedSecret: this.totpSharedSecrets[item.credId]
      }
    }

    const dialogRef = this.confirmDialog.open(RegisterOAthComponent, dc);
    dialogRef.afterClosed().subscribe((response) => {
      if(response.status==='ok') {
        this.getCreds();
      } else {
        this.deleteCred(item);
      }
    });
  }

  registerEmailOrSmsCredentials(item: any) {
    let dc: MatDialogConfig = {
      data: {
        credId: item.credId,
        credName: item.credValue,
        credType: item.credType
      }
    }

    const dialogRef = this.confirmDialog.open(RegisterEmailOrSmsComponent, dc);
    dialogRef.afterClosed().subscribe((response) => {
      if(response.status==='ok') {
        this.getCreds();
      } else {
        this.deleteCred(item);
      }
    });
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
