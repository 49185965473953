<div class="main-console-container container-fluid drop-shadow p-md-5  gx-0">

  <div class="row mb-3">
    <!-- Title -->
    <div class="col">
      <div class="main-console-title h-100">
        Cookie Policy Information
      </div>
    </div>

    <!-- Regional Choice -->
    <div class="col-12 col-md-6">
      <div class="main-console-card p-3 p-md-4 h-100">
        <h5>Collection and Use of Your Information (including via Cookies)</h5>
        <span>
        Please review the Cookie Policy for your region or country of residence:
      </span>
        <ul>
          <li>
            <a class="reference" routerLink="/emea-cookies">EMEA Region</a>
          </li>

          <li>
            <a class="reference" href="https://www.bnymellon.com/us/en/data-privacy.html">All Other Regions</a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>
