<div class="main-console-container container-fluid drop-shadow p-md-5" >

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      FIDO Device Authentication
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">

      <form [formGroup]="loginForm" novalidate class="h-100" [style.visibility]="ready? 'visible' : 'hidden'">
        <!-- First Flex Element -->
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <div class="row" *ngIf="displayService.fidoScreenMode">
            <div class="col-12" *ngIf="displayService.fidoScreenMode==='FIDO'">
              Validate your biometric
            </div>
            <div class="col" *ngIf="displayService.fidoScreenMode==='SECURITYKEY'">
              Validate your security key
            </div>
            <i class="col-12">It may take a few moments to appear</i>
          </div>
        </div>


        <!-- Second Flex Element -->
        <div class="key">
          <img *ngIf="displayService.fidoScreenMode==='FIDO'" src="assets/img/biometric.svg" style="height:70px;padding:20px;" alt=""/>
          <img *ngIf="displayService.fidoScreenMode==='SECURITYKEY'" src="assets/img/security-key.svg" style="height:70px;padding:20px;" alt=""/>
        </div>

        <!-- Third Flex Element -->
        <div class="row mt-3">
          <div class="col" *ngIf="fidoService.isInteractionButtonToBeDisplayed() || globalService.isRememberDeviceEnabled() || displayService.isFIDOContinousFlow()" (click)="login()">
            <button class="primary-btn" [disabled]="fidoRegVerifyBtnClicked">
              Next
            </button>
          </div>
          <div class="col ms-auto" *ngIf="factorSelection">
            <button class="secondary-btn " (mousedown)="factorSelect()">
              More Options
            </button>
          </div>
        </div>
        <!-- Fourth Flex Element -->
        <div>
          <app-userinfo></app-userinfo>
          <app-loading *ngIf="!ready && !errorDiv"></app-loading>
        </div>
      </form>

    </div>
  </div>
</div>
