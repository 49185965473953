import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {EnvApiData} from "./EnvApiData";

@Injectable({providedIn: 'root'})
export class EnvService{
  public authData: EnvApiData;

  constructor(private http: HttpClient,
              private httpBackend: HttpBackend)
  {  }

  getEnvJson(): Observable<any>{
    const httpClient = new HttpClient(this.httpBackend);
    return httpClient.get("v1/config/environment");
  }

  public getProxyURL(authData:EnvApiData) {
    return "/common/portal/proxy/";
  }

}



