<div class="main-console-container drop-shadow container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      SMS One Time Passcode Terms of Service
    </div>

    <div class="main-console-card flex-space col-md-6 text-justify ms-auto">
      <span>
        <h5>SMS Terms of Service</h5>
        Please review our Terms of Service regarding SMS One Time Passcodes:
        <ul>
          <li>By using the SMS OTP authentication mechanism, you opt-in to receiving One Time Passcodes via SMS messages</li>
          <li>One message per login</li>
          <li>Message and data rates may apply</li>
          <li>Reply HELP for help</li>
          <li>Reply STOP to opt out</li>
          <li><a class="reference" href="https://www.bnymellon.com/us/en/data-privacy.html">Data Privacy Statement</a></li>
        </ul>
      </span>
    </div>
  </div>
</div>
