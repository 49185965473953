import { Component, OnInit } from '@angular/core';
import {DisplayService} from "../service/display.service";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html'
})
export class HelpPageComponent implements OnInit {

  clientPhoneMap = new Map<String, String>();
  internalPhoneMap = new Map<String, String>();
  userName;
  clientTxId;
  lastTransaction;
  hasValues: boolean;

  constructor(    private clipboard: Clipboard,
                  private displayService: DisplayService) { }

  ngOnInit(): void {
    this.userName = this.displayService.userName;
    this.clientTxId = this.displayService.clientTransactionId;
    this.lastTransaction = this.displayService.lastTransaction;
    this.hasValues = (this.userName != null || this.clientTxId != null || this.lastTransaction != null);

    this.clientPhoneMap.set("Asia Pacific Toll Free", "+800 2265 6369");
    this.clientPhoneMap.set("Asia Pacific", "+65 6432 0314");
    this.clientPhoneMap.set("Europe", "+44 (0) 207 964 6161");
    this.clientPhoneMap.set("US Toll Free", "+1 (855) 284 9065");
    this.clientPhoneMap.set("US", "+1 615-457-5589");

    this.internalPhoneMap.set("Asia Pacific", "+65 6424 0399");
    this.internalPhoneMap.set("Europe", "+44 207 163 4357");
    this.internalPhoneMap.set("US Toll Free", "+1 855 435 4357");
  }

  onCopyToClipboard() {
    let copyData = {};
    copyData["timestamp"] = this.lastTransaction;
    copyData["clientTxId"] = this.clientTxId;
    if(this.userName) {
      copyData["userName"] = this.userName;
    }
    let copyTxt = JSON.stringify(copyData);
    this.clipboard.copy(copyTxt);
  }
}
