<div class="main-console-container container-fluid drop-shadow p-md-5" >

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      <img src="assets/img/rsa_with_white.png" class="securid-label" alt=""/>
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">

      <form class="h-100" [formGroup]="securidForm" novalidate [style.visibility]="ready ? 'visible' : 'hidden'">
        <!-- First Flex Element -->
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <span>Please enter your pin and passcode</span>
        </div>

        <div>
          <app-otp-input [hideEntry]="true" inputSize="10" (otpEntryChange)="onOtpEntryChange($event)"></app-otp-input>
        </div>

        <!-- Third Flex Element -->
        <div class="row mt-3">
          <div class="col">
            <button class="secondary-btn" (mousedown)="factorSelect()" *ngIf="factorSelection">
              More Options
            </button>
          </div>
        </div>

        <!-- Fourth Flex Element -->
        <div>
          <app-userinfo></app-userinfo>
        </div>
      </form>

      <app-loading *ngIf="!ready && !errorDiv"></app-loading>

    </div>
  </div>
</div>
