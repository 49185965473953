<div class="main-console-container drop-shadow container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Logout Successful
    </div>

    <div class="main-console-card flex-space col-md-6 text-justify ms-auto">

      <div class="h4 text-wrap mb-0">
        <fa-icon [icon]="leaveIcon" class="px-2 d-inline"></fa-icon>
        Signed out of your account.
      </div>

      <div class="my-4">
        Thank you for using AuthHub. You can log back into your account at any time.
      </div>

      <form class="do-not-flex" novalidate>
        <button id="login-btn" type="login" class="primary-btn align-bottom container-fluid mb-0" routerLink="/direct">
          <img src="assets/img/favicon.png" class="white-bnym-logo" />
          Log in again
        </button>
      </form>
    </div>
  </div>
</div>
