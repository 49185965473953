import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  Output,
  EventEmitter,
  AfterViewChecked
} from '@angular/core';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit, AfterViewChecked {
  @ViewChildren('otpField') inputElements: QueryList<ElementRef>;
  @Input() inputSize: number;
  @Input() hideEntry: boolean;
  @Output() otpEntryChange = new EventEmitter<{otpInput: string, submit: boolean}>();

  allDigitInputs: Array<number>;
  fieldWidth: number;
  minWidth: number;
  otpErrorMsg: string;

  constructor() { }

  ngOnInit(): void {
    // Default to 6 digit input
    this.inputSize = this.inputSize ? Number(this.inputSize) : 6;
    this.allDigitInputs = new Array<number>(this.inputSize);
    this.fieldWidth = (1/(this.inputSize*2))*100;
    this.minWidth = (1/(this.inputSize))*100;
  }

  ngAfterViewChecked(): void {
    if (this.allDigitInputs.join("").length == 0) {
      this.focusOtpField();
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onKeydown(event, i: number) {
    if(event.key !== "Tab" && !event.ctrlKey) {
      event.preventDefault();
    }

    // Check for illegal characters - alphabetic, special, and whitespace characters
    if (!event.ctrlKey && (/[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]|^[a-zA-Z]$/).test(event.key)) {
      this.updateInput(false, "Invalid character. Only numbers are accepted as input.");
    }
    else if ((/[0123456789]/).test(event.key)) {
      this.onDigit(event, i);
    }
    else if (event.key === "ArrowLeft") {
      this.focusElement(i-1);
    }
    else if (event.key === "ArrowRight") {
      this.focusElement(i+1);
    }
    else if (event.key === 'Enter') {
      this.updateInput(true);
    }
    else if (event.key === 'Backspace' || event.key === 'Delete') {
      this.onErase(i);
    }
  }

  private focusElement(i: number) {
    if(i < this.inputSize && i >= 0) {
      this.inputElements.get(i).nativeElement.select();
    }
  }

  private updateInput(submit: boolean, errorMsg?: string) {
    var otpCode = this.allDigitInputs.join("");

    // Validate submission
    if (submit) {
      if (otpCode.length !== this.inputSize) {
        errorMsg = errorMsg ? errorMsg : "Security code must be " + this.inputSize + " digits long.";
        submit = false;
      }

    }
    this.otpErrorMsg = submit && !errorMsg ? this.validateLength() : errorMsg

    // Emit otp code change
    this.otpEntryChange.emit(
      {'otpInput' : this.allDigitInputs.join(""),
        'submit' : submit}
    );

    if (submit) {
      this.resetInputs();
    }
  }

  private onErase(i: number) {
    if (this.allDigitInputs[i] !== null && this.allDigitInputs[i] !== undefined) {
      this.allDigitInputs[i] = null;
    }
    else if (i>0) {
      this.focusElement(i-1);
      this.allDigitInputs[i-1] = null;
    }
    this.updateInput(false);
  }

  onPaste(event: ClipboardEvent, index: number) {
    event.preventDefault();
    let pastedText = event.clipboardData.getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const character = pastedText.charAt(i);
      let keydown = new KeyboardEvent('keydown', {key: character});
      let modIndex = index+i;
      if(modIndex < this.inputSize) {
        this.onKeydown(keydown, index+i);
      }
    }
  }

  private onDigit(event, i: number) {
    this.allDigitInputs[i] = Number(event.key);
    if (i === this.inputSize-1) {
      this.updateInput(true);
    } else {
      this.focusElement(i+1);
      this.updateInput(false);
    }
  }

  private validateLength() {
    if (this.allDigitInputs.join("").length !== this.inputSize) {
        return  "Security code must be " + this.inputSize + " digits long.";
    }
  }

  private resetInputs() {
    for (var i = 0; i < this.inputSize; i++) {
      this.allDigitInputs[i] = null;
    }
  }

  focusOtpField() {
    // Focus on first element
    this.inputElements.get(0).nativeElement.select();
  }
}
