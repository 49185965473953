import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DisplayService } from '../service/display.service';
import { FactorselectionService } from '../service/factorselection.service';
import { GlobalService } from '../service/global.service';
import { LdapService } from '../service/ldap.service';
import { MotpService } from '../service/motp.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-motp',
  templateUrl: './motp.component.html',
  styleUrls: ['./motp.component.scss']
})
export class MotpComponent implements OnInit, OnDestroy {
  multiFactor: boolean = false;
  ready: boolean = false;
  qrCodeEnabled: boolean = false;
  registerMobileOtp: boolean = false;
  credId = '';
  mobileOtpOptions: string[] = [];
  verifyMobileOtpForm: UntypedFormGroup;

  errorDiv: boolean = false;
  errorMsg: String;
  factorSelection: boolean = false;
  selectedCredValue: string;
  credentialId: string;
  credValue: string;
  otpEntry: string;
  validEntry: boolean;
  pageSubs: Subscription[] = new Array<Subscription>();


  constructor(private factorselectionService: FactorselectionService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private motpService: MotpService,
              private LdapService: LdapService,
              private displayService: DisplayService,
              public globalService: GlobalService) {
  }

  ngOnInit() {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }
    this.factorSelection = this.displayService.factorSelection;
    this.verifyMobileOtpForm = this.formBuilder.group({
      otp: ['', Validators.required],
      sharedSecret: ['']
    })
    this.multiFactor = this.displayService.multiFactor;
    this.registerMobileOtp = this.displayService.registerMobileOtp;
    this.mobileOtpOptions = this.displayService.otpOptions;
    this.mobileOtpOptions.forEach(function (value) {
      if (value["default"] === true) {
        this.credId = value["credId"];
        this.selectedCredValue = value["credValue"];
      }
    }.bind(this));
    if (this.mobileOtpOptions.length === 1) {
      this.credId = (<any>this.mobileOtpOptions[0]).credId;
      this.selectedCredValue = (<any>this.mobileOtpOptions[0]).credValue;
      this.onMobileOTPGenerate();
    }
  }

  selectedCredential(event: any) {
    if (event) {
      this.credId = event.credId;
      this.selectedCredValue = event.credValue;
    }
    this.onMobileOTPGenerate();
  }

  onMobileOTPGenerate() {
    this.ready = true;
    this.multiFactor = false;
  }

  onMobileOTPVerify() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs = [this.motpService.verifyMobileOtp(this.otpEntry, this.credId, this.selectedCredValue)
      .subscribe({
        next: (data) => {
          this.displayService.routeActions(data);
        },
        error: (err) => {
          this.errorDiv = this.displayService.errorDiv;
          this.errorMsg = this.displayService.errorMsg;
          this.ready = true;
        }
      })
    ];
  }

  enableVipAccess() {
    this.registerMobileOtp = true;
  }

  factorSelect() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  onOtpEntryChange(event) {
    this.otpEntry = event.otpInput;
    if (event.submit) {
      this.onMobileOTPVerify();
    }
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
