import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class MotpService {

  constructor(
    private http: HttpClient,
    private displayService: DisplayService
  ) { }

  public verifyMobileOtp(otp, credID, credValue) {
    const postData = { credId: credID, otp: otp, credValue: credValue, deviceTag: true };
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/MobileOTPVerifier', postData);
  }

  public registerMobileOtp(credType) {
    const postData = { credType: credType, default: false, deviceTag: true };
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/MobileOTPRegistrar', postData);
  }

  public registerVipMobileOtp(credType, credValue, vipCredentialID, otp) {
    const postData = { credType: credType, credValue: credValue, vipCredentialID: vipCredentialID, otp: otp, deviceTag: true }
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/VIPCredentialRegistrar', postData);
  }
}
