<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Restart Required Notice
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">
      <div class="flex-space h-100">
        <div>
          <app-errormsg class="mb-2" [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <div>Unrecoverable error. Please restart login or try again later.</div>
        </div>

        <div class="my-2">
          <div *ngIf="!isOauthFlow" class="primary-btn gx-0 pointerOnHover" (click)="restartAuthFlow()">
            Click to retry login
          </div>
        </div>

        <app-userinfo></app-userinfo>
      </div>
    </div>
  </div>
</div>
