import { AuthState, AuthStateService } from "../service/auth-state.service";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";


/**
 * Route navigation guard for protected pages. Redirects to the home page if user is not logged in.
 */
export const authGuard = () => {
  const authStateService = inject(AuthStateService);
  const router = inject(Router);

  if (authStateService.isAuthenticated()) {
    return true;
  } else {
    router.navigate(["/home"]);
    return false;
  }
}

/**
 * Route navigation guard for initial authentication pages. Redirects to welcome page if user is already logged in.
 */
export const initFlowGuard = () => {
  const authStateService = inject(AuthStateService);
  const router = inject(Router);

  if (authStateService.isAuthenticated()) {
    router.navigate(["/welcome"])
    return false;
  } else {
    return true;
  }
}

/**
 * Route navigation guard for factor authentication pages
 */
export const factorFlowGuard = () => {
  const authStateService = inject(AuthStateService);
  const router = inject(Router);

  if (authStateService.getAuthStatus() === AuthState.FACTOR) {
    return true;
  } else if (authStateService.isAuthenticated()) {
    router.navigate(["/welcome"])
    return false;
  } else {
    router.navigate(["/home"]);
    return false;
  }
}





