<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Factor Selection
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">
      <form class="h-100" novalidate [style.visibility]="ready? 'visible' : 'hidden'" name="factorSelection">
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <span>Please select an authentication method</span>
        </div>

        <mat-radio-group (change)="onChange($event)" name="factorSelection" id="factorSelection">
          <div class="row py-1 gx-0" id="factors-list" *ngFor="let factorName of factorOptions">
            <label>
              <mat-radio-button disableRipple id="{{factorName}}" value={{factorName}}>
                <span [innerHTML]="labelMap[factorName] || factorName"></span>
              </mat-radio-button>
            </label>
          </div>

          <label class="row checkbox-label my-3" for="rememberFactor">
            <mat-checkbox disableRipple id="rememberFactor" name="rememberFactor" [(ngModel)]="enableRememberFactor">
              Remember my choice
              <img src="assets/img/help-icon.svg" title="The selected authentication factor will be saved for future logins with your account." alt="Remember factor"
                   class="tooltip-icon"/>
            </mat-checkbox>
          </label>
        </mat-radio-group>

        <button id="select-btn" class="primary-btn mb-2" (click)="onSelect()" [disabled]="!factorSelection">
          Select
        </button>

        <div>
          <app-userinfo></app-userinfo>
          <app-loading *ngIf="!ready"></app-loading>
        </div>
      </form>


    </div>
  </div>
</div>
