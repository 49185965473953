import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmationDialogData {
  message: string
  btnYes: boolean
  btnSave: boolean
  btnDiscard: boolean
}

@Component({
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}

  onYes() {
    this.dialogRef.close({response: 'yes'});
  }

  onSave() {
    this.dialogRef.close({response: 'save'});
  }

  onDiscard() {
    this.dialogRef.close({response: 'discard'});
  }

  onCancel() {
    this.dialogRef.close({response:'cancel'});
  }

}

