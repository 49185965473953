<head>
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
</head>

<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Identity Provider
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto flex-space">
      <div>
        <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
        <span *ngIf="!errorDiv">Reading Authentication Response</span>
      </div>
      <div class="my-4">
        <mat-progress-bar *ngIf="!errorDiv" mode='indeterminate'></mat-progress-bar>
      </div>
      <div>
        <app-userinfo></app-userinfo>
      </div>
    </div>
  </div>
</div>
