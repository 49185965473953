import {Component} from '@angular/core';

// The load function from emea-region-compliance.js
// declare function loadNotice(): any;

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html'
})
export class CookiesPolicyComponent {

}
