import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { DisplayService } from '../service/display.service';
import { FactorselectionService } from '../service/factorselection.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-multi-credentials',
  templateUrl: './multi-credentials.component.html'
})
export class MultiCredentialsComponent implements OnInit, OnDestroy {

  @Input() errorMsg: string;
  @Input() credentialOptions: any[];
  @Input() buttonLabel: string;
  @Output() selectedCredential = new EventEmitter();
  credential: any;
  factorSelection: boolean = false
  clicked: boolean = false;
  ready: boolean;
  errorDiv: any;
  pageSubs: Subscription[] = new Array<Subscription>();

  constructor(private factorselectionService: FactorselectionService, private router: Router, private displayService: DisplayService) { }

  ngOnInit(): void {
    this.factorSelection = this.displayService.factorSelection;
    this.handleDefaultSelection();
    this.ready = true;
  }

  handleDefaultSelection() {
    if(this.credentialOptions) {
      for (let cred of this.credentialOptions) {
        if (cred.default) {
          this.credential = cred;
          break;
        }
      }
    }
  }

  onSelect(cred) {
    this.credential = cred;
  }

  onSendCode() {
    console.log(this.credential);
    this.clicked = true;
    this.selectedCredential.emit(this.credential);
    this.displayService.focusNthField(0, 200);
  }

  factorSelect() {
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

}
