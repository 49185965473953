import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl, Validators} from "@angular/forms";
import {SelfService} from "../../service/self.service";



export interface RegisterOAthData {
  credId: string,
  credName: string,
  sharedSecret: string
}

/**
 * Pops up QR code to register OAth authenticator
 */
@Component({
  templateUrl: './register-oath-component.html',
  styleUrls: ['./register-oath-component.scss']
})
export class RegisterOAthComponent {
  errorMsg: string;
  submitted = false;
  otpValueControl = new UntypedFormControl('', [Validators.required, Validators.pattern('^\\d+$')]);

  constructor(
    public dialogRef: MatDialogRef<RegisterOAthComponent>,
    private selfService: SelfService,
    @Inject(MAT_DIALOG_DATA) public data: RegisterOAthData
  ) {
  }

  onVerifyOTP () {
    let otp = this.otpValueControl.value;
    this.submitted = true;
    this.selfService.verifyMobileOtp(this.data.credId, this.data.credName, otp).subscribe({
      next: (res) => {
        this.dialogRef.close({status: 'ok'});
      },
      error: (err) => {
        this.errorMsg = "Invalid OTP";
        this.submitted = false;
      }
    });
  }

  onCancel() {
    this.dialogRef.close({status: 'cancel'});
  }

}
