import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';


@Injectable({
  providedIn: 'root'
})
export class FidoService {

  constructor(private http: HttpClient,
              private displayService: DisplayService) { }

  authenticationGenerateChallenge(userName) {
    const postData = {
      documentDomain: location.origin,
      fidoCredType: this.displayService.fidoCredType,
      deviceTag: true
    };

    if (userName) {
      postData["userName"] = userName;
    }
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/FIDOAuthChallengeGenerator', postData);
  }

  public sendLoginVerifychallenge(makeCredReq) {
    return this.http.post(this.displayService.proxyUrl + 'factor/v1/FIDOAuthChallengeVerifier', makeCredReq);
  }

  isInteractionButtonToBeDisplayed() {
    /**
     * If the browser is Safari, then the button should be displayed always.
     */
    return navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") <= -1;
  }

}
