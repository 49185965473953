import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl, Validators} from "@angular/forms";
import {SelfService} from "../../service/self.service";

export interface RegisterEmailorSmsData {
  credId: string,
  credName: string,
  credType: string
}

/**
 * Pops up QR code to register OAth authenticator
 */
@Component({
  templateUrl: './register-email-sms-component.html',
  styleUrls: ['./register-email-sms-component.scss']
})
export class RegisterEmailOrSmsComponent {
  errorMsg: string;
  submitted = false;
  otpValueControl = new UntypedFormControl('', [Validators.required, Validators.pattern('^\\d+$')]);

  constructor(
    public dialogRef: MatDialogRef<RegisterEmailOrSmsComponent>,
    private selfService: SelfService,
    @Inject(MAT_DIALOG_DATA) public data: RegisterEmailorSmsData
  ) {
  }

  onVerifyOTP() {
    let otp = this.otpValueControl.value;
    this.submitted = true;
    if (otp && otp.trim()) {
      this.selfService.otpVerifier(this.data.credId, otp).subscribe({
        next: (res) => {
          this.dialogRef.close({status: 'ok'});
        }, error: (err) => {
          let msg = "Invalid OTP";
          this.submitted = false;
        }
      });
    }
  }

  onCancel() {
    this.dialogRef.close({status: 'cancel'});
  }

}
