import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {GlobalService} from "./global.service";
import {TokenService} from "./token.service";

/**
 * Enum denoting the user's auth state
 */
export const enum AuthState {
  NONE,
  FACTOR,
  AUTHENTICATED,
  RESTART_NEEDED
}

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {

  readonly SEC_TO_MS          = 1000;
  readonly MIN_TO_SEC         = 60;
  readonly MIN_IN_MS          = this.MIN_TO_SEC * this.SEC_TO_MS;
  readonly IDLE_TIMER_MS      = 10 * this.MIN_IN_MS;
  readonly REFRESH_BUFFER_MS  = 5 * this.MIN_IN_MS;

  private authStatusSource: BehaviorSubject<AuthState>;
  clientAccessToken: string;
  userAccessToken: string;
  refreshToken: string;
  expiresDate: Date;
  refreshTimeoutId: number;
  idleTimeoutId: number;

  constructor( private tokenService: TokenService,
               private globalService: GlobalService,
               private oauth2Service: TokenService,
               private router: Router
  ) {
    this.authStatusSource = new BehaviorSubject(AuthState.NONE);
  }

  getAuthStatusSource() {
    return this.authStatusSource;
  }

  getAuthStatus(): AuthState {
    return this.authStatusSource.getValue();
  }

  setExpiration(expSec: number) {
    this.expiresDate = new Date(new Date().getTime() + expSec*this.SEC_TO_MS);
    this.setRefreshTimeout(expSec);
    this.setIdleTimer();
  }

  isNotExpired() {
    return (this.expiresDate > new Date());
  }

  setRefreshTimeout(expSec: number) {
    clearInterval(this.refreshTimeoutId);
    // Set refresh timer for 5 minutes before expiration
    this.refreshTimeoutId = setTimeout(() => { this.doRefresh() }, expSec*this.SEC_TO_MS - this.REFRESH_BUFFER_MS);
  }

  setIdleTimer() {
    if (this.isAuthenticated()) {
      clearInterval(this.idleTimeoutId);
      // Set idle timer to logout after set time
      this.idleTimeoutId = setTimeout(() => { this.doLogout() }, this.IDLE_TIMER_MS)
    }
  }

  resetAuthState() {
    this.clientAccessToken = null;
    this.userAccessToken = null;
    this.clientAccessToken = null;
    this.refreshToken = null;
    clearInterval(this.idleTimeoutId);
    clearInterval(this.refreshTimeoutId);
    this.setAuthStatusNone();
  }

  isAuthenticated(): boolean {
    return (this.authStatusSource.getValue() === AuthState.AUTHENTICATED && this.isUatValid())
  }

  isUatValid() {
    return (this.isNotExpired() && !(this.userAccessToken == null))
  }

  // Retrieve data from Http response, then set the appropriate auth status
  parseUatResponse(response: Object) {
    if(!response['access_token']) {
      // This is in development, when the response does not contain the access token
      const idtoken = response['id_token'];
      this.oauth2Service.getUserAccessToken(idtoken).subscribe( tokenResponse => {
        this.setExpiration(tokenResponse?.['expires_in']);
        this.userAccessToken = tokenResponse['access_token'];
        this.refreshToken = tokenResponse?.['refresh_token'];
        this.setAuthStatusAuthenticated();
        // There is a bit of delay after login, then the user is navigated to welcome page
        this.router.navigate(['welcome']);
      })
    } else {
      this.setExpiration(response?.['expires_in']);
      this.userAccessToken = response['access_token'];
      this.refreshToken = response?.['refresh_token'];
      this.setAuthStatusAuthenticated();
    }
  }

  doRefresh() {
    if (this.isNotExpired() && this.userAccessToken) {
      console.log("Extending session");
      this.tokenService.refreshAccessToken(this.refreshToken).subscribe({
        next: response => {
          this.parseUatResponse(response);
        },
        error: () => {
          this.doLogout();
        }
      });
    }
  }

  doLogout() {
    console.log("Logout");
    const refreshToken = this.refreshToken;
    this.resetAuthState();
    this.tokenService.revokeTokens(refreshToken).subscribe({
      next: (data) => {
        this.router.navigate(["signed-out"]);
      },
      error: (err) => {
        console.log(err);
        this.router.navigate(["signed-out"]);
      }
    });
  }

  public setAuthStatusNone() {
    this.authStatusSource.next(AuthState.NONE);
  }

  public setAuthStatusFactor() {
    this.authStatusSource.next(AuthState.FACTOR);
  }

  setAuthStatusRestartNeeded() {
    this.authStatusSource.next(AuthState.RESTART_NEEDED);
    this.router.navigate(['restartRequired']);
  }

  private setAuthStatusAuthenticated() {
    if (this.isUatValid()) {
      this.authStatusSource.next(AuthState.AUTHENTICATED);
    } else {
      this.resetAuthState();
    }
  }
}
