<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Mobile OTP Verification
    </div>

    <div class="main-console-card flex-space col-md-6 text-justify ms-auto">

      <div *ngIf="multiFactor" class="h-100">
        <app-multi-credentials class="h-100" [errorMsg]="errorMsg" [credentialOptions]='mobileOtpOptions' buttonLabel='Continue'
                               (selectedCredential)="selectedCredential($event)"></app-multi-credentials>
      </div>


      <form class="flex-grow-1 mt-2" *ngIf="!multiFactor" [formGroup]="verifyMobileOtpForm" novalidate
            [style.visibility]="ready? 'visible' : 'hidden'">

        <!-- First Flex Element -->
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <span>
            Enter Mobile OTP from device {{selectedCredValue}}
          </span>
        </div>

        <!-- Second Flex Element -->
        <div class="row">
          <app-otp-input (otpEntryChange)="onOtpEntryChange($event)"></app-otp-input>
        </div>

        <!-- Third Flex Element -->
        <div class="row">
          <div class="ms-auto">
            <button class="secondary-btn" (mousedown)="factorSelect()" *ngIf="factorSelection">
              More Options
            </button>
          </div>
        </div>

        <!-- Fourth Flex Element -->
        <div>
          <app-userinfo></app-userinfo>
          <app-loading *ngIf="!ready && !errorDiv"></app-loading>
        </div>
      </form>


    </div>
  </div>
</div>
