<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Push Authentication
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">

      <div *ngIf="multifactor" class="h-100">
        <app-multi-credentials class="h-100" [errorMsg]="errorMsg" [credentialOptions]='pushOptions' buttonLabel='Continue'
                               (selectedCredential)="selectedCredential($event)"></app-multi-credentials>
      </div>

      <div class="flex-space h-100" *ngIf="!multifactor" [style.visibility]="ready? 'visible' : 'hidden'">
        <!-- First Flex Element -->
        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>

          <div class="visible">
            <span *ngIf="ready && !errorDiv">Accept push request on your {{selectedCredValue}} to login.</span>
            <span *ngIf="ready && timeoutErr">Resend your push request to {{selectedCredValue}}.</span>
            <span *ngIf="!ready">{{pushStatusMsg}}</span>
          </div>

          <!-- Smart Phone Icon -->
          <div class="text-center pt-3">
            <fa-icon [icon]="phoneIcon" style="font-size: 3rem" title="Phone Icon"></fa-icon>
          </div>
        </div>

        <!-- Second Flex Element -->
        <div class="row mt-3">
          <div class="col" *ngIf="timeoutErr">
            <button class="primary-btn" (mousedown)="onGenerate()">
              <fa-icon [icon]="resendIcon" class="pe-2 text-white" title="Resend Icon"></fa-icon>
              Resend
            </button>
          </div>
          <div class="col">
            <button class="secondary-btn" (mousedown)="chooseAnother()">More Options</button>
          </div>
        </div>

        <!-- Third Flex Element -->
        <div>
          <app-userinfo></app-userinfo>
          <app-loading *ngIf="!ready && !errorDiv"></app-loading>
        </div>
      </div>

    </div>
  </div>
</div>
