import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { decode, encode } from '../shared/base64';
import { DisplayService } from '../service/display.service';
import { FactorselectionService } from '../service/factorselection.service';
import { FidoService } from "../service/fido.service";
import { GlobalService } from '../service/global.service';
import {Subscription} from "rxjs";
import {RememberInfoService} from "../service/rememberInfo.service";

@Component({
    selector: 'app-fido',
    templateUrl: './fido.component.html'
})
export class FidoComponent implements OnInit, OnDestroy {

  enabled = false;
  loginEnabled = false;
  registrationEnabled: boolean = false;
  loginProgress = false;
  loginForm: UntypedFormGroup;
  xFlow: string;
  data = null;
  errorDiv: any;
  errorMsg: any;
  fidoFactors: boolean = false;
  fidoOptions: string[] = [];
  factorSelection: boolean = false;
  fidoRegVerifyBtnClicked = false;
  ready: boolean = true;
  pageSubs: Subscription[] = new Array<Subscription>();

  constructor(private factorselectionService: FactorselectionService,
                private formBuilder: UntypedFormBuilder,
                private router: Router,
                public fidoService: FidoService,
                public displayService: DisplayService,
                public globalService: GlobalService) { }

    ngOnInit() {
        if (this.displayService.userName == null) {
            this.router.navigate(["../"]);
        }
        this.displayService.setFidoComponentResetFunction(this.doInit.bind(this));
        this.doInit();
    }

    doInit() {
        this.fidoRegVerifyBtnClicked = false;
        this.loginProgress = false;
        this.factorSelection = this.displayService.factorSelection;

        this.loginForm = this.formBuilder.group({
            userName: ['', Validators.required],
        });
        if (this.displayService.action === "FIDO_AUTH_GENERATE_CHALLENGE" || this.displayService.action === "SECURITYKEY_AUTH_GENERATE_CHALLENGE") {
            this.fidoFactors = this.displayService.fidoFactors;
            this.loginProgress = true;
            this.enabled = false;
            this.loginEnabled = false;
            if (!(this.globalService.isRememberDeviceEnabled() || this.displayService.isFIDOContinousFlow())) {
                let nextActionTrace_current = this.displayService.getNextActionTrace().current;
                if ((this.displayService.action === "FIDO_AUTH_GENERATE_CHALLENGE" && nextActionTrace_current === "FIDO_AUTH_GENERATE_CHALLENGE")
                    || (this.displayService.action === "SECURITYKEY_AUTH_GENERATE_CHALLENGE" && nextActionTrace_current === "SECURITYKEY_AUTH_GENERATE_CHALLENGE")) {
                  this.login();
                }
            }
        }
        if (this.displayService.action === "FIDO_REGISTER_GENERATE_CHALLENGE" || this.displayService.action === "SECURITYKEY_REGISTER_GENERATE_CHALLENGE") {
            this.registrationEnabled = this.displayService.registrationEnabled;
            this.loginProgress = this.displayService.loginProgress;
        }
        this.fidoOptions = this.displayService.fidoOptions;
        this.displayService.focusNthField();
    }

    publicKeyCredentialToJSON = (pubKeyCred) => {
        if (pubKeyCred instanceof Array) {
            let arr = [];
            for (let i of pubKeyCred)
                arr.push(this.publicKeyCredentialToJSON(i));
            return arr
        }
        if (pubKeyCred instanceof ArrayBuffer) {
            encode(pubKeyCred);
            return encode(pubKeyCred)
        }
        if (pubKeyCred instanceof Object) {
            let obj = {};
            for (let key in pubKeyCred) {
                obj[key] = this.publicKeyCredentialToJSON(pubKeyCred[key])
            }
            return obj
        }
        return pubKeyCred
    }

    /**
    * Decodes arrayBuffer required fields.
    */
    preformatGetAssertReq = (getAssert) => {
        getAssert["data"]["challenge"] = decode(getAssert["data"]["challenge"]);
        for (let allowCred of getAssert["data"]["allowCredentials"]) {
            allowCred["id"] = decode(allowCred["id"]);
        }
        return getAssert["data"]
    }

    sendLoginVerifychallenge(makeCredResponse) {
      makeCredResponse.deviceTag = true;
      this.ready = false;
      this.pageSubs.push(
        this.fidoService.sendLoginVerifychallenge(makeCredResponse).subscribe({
          next: (response) => {
            this.displayService.routeActions(response);
          },
          error: (err) => {
            this.ready = true;
            this.errorDiv = this.displayService.errorDiv;
            this.errorMsg = this.displayService.errorMsg;
          }
        })
      );
    }

    onLogin() {
        this.loginUsername();
    }

    loginUsername() {
      this.ready = false;
      this.pageSubs.push(
        this.fidoService.authenticationGenerateChallenge(this.loginForm.value.userName).subscribe({
          next: (data) => {
            this.ready = true;
            this.loginProgress = true;
            this.enabled = false;
            this.loginEnabled = false;
            this.data = data
            if ((this.displayService.fidoCredType === "FIDO" && data["nextaction"] === "FIDO_AUTH_VERIFY_CHALLENGE") || (this.displayService.fidoCredType === "SECURITYKEY" && data["nextaction"] === "SECURITYKEY_AUTH_VERIFY_CHALLENGE")) {
              this.displayService.flowState = data["flowState"];
              if (sessionStorage.getItem("__authn_debug000")) {
                let option = sessionStorage.getItem("__authn_debug000");
                if (("" + option) === ("" + window["RSWF"])) {
                  this.data["data"]["allowCredentials"].forEach((r) => {
                    r["transports"] = ["internal"];
                  });
                } else if (("" + option) === ("" + window["RFWS"])) {
                  this.data["data"]["allowCredentials"].forEach((r) => {
                    r["transports"] = ["usb", "ble", "nfc"];
                  });
                }
              }
              let publicKey = this.preformatGetAssertReq(this.data);
              return navigator.credentials.get({publicKey}).then
              ((response) => {
                let getAssertionResponse = this.publicKeyCredentialToJSON(response);
                return this.sendLoginVerifychallenge(getAssertionResponse);
              }).catch((err) => {
              });
            } else {
              alert("error in data from server");
            }
          },
          error: (err) => {
            this.ready = true;
            this.errorDiv = this.displayService.errorDiv;
            this.errorMsg = this.displayService.errorMsg;
          }
        })
      );
    }

    login() {
      this.fidoRegVerifyBtnClicked = true;
      this.ready = false;
      this.pageSubs.push(
        this.fidoService.authenticationGenerateChallenge(this.displayService.userName).subscribe({
          next: (data) => {
            this.ready = true;
            this.data = data
            if ((this.displayService.fidoCredType === "FIDO" && data["nextaction"] === "FIDO_AUTH_VERIFY_CHALLENGE") || (this.displayService.fidoCredType === "SECURITYKEY" && data["nextaction"] === "SECURITYKEY_AUTH_VERIFY_CHALLENGE")) {
              if (sessionStorage.getItem("__authn_debug000")) {
                let option = sessionStorage.getItem("__authn_debug000");
                if (("" + option) === ("" + window["RSWF"])) {
                  this.data["data"]["allowCredentials"].forEach((r) => {
                    r["transports"] = ["internal"];
                  });
                } else if (("" + option) === ("" + window["RFWS"])) {
                  this.data["data"]["allowCredentials"].forEach((r) => {
                    r["transports"] = ["usb", "ble", "nfc"];
                  });
                }
              }
              let publicKey = this.preformatGetAssertReq(this.data);
              return navigator.credentials.get({publicKey}).then((response) => {
                let getAssertionResponse = this.publicKeyCredentialToJSON(response);
                return this.sendLoginVerifychallenge(getAssertionResponse)
              }).catch((err) => {
                this.fidoRegVerifyBtnClicked = false;
              });
            } else {
              alert("error in data from server");
              this.router.navigate(['']);
            }
          },
          error: (err) => {
            this.ready = true;
            this.fidoRegVerifyBtnClicked = false;
            this.errorDiv = this.displayService.errorDiv;
            this.errorMsg = this.displayService.errorMsg;
          }
        })
    );
  }

  factorSelect() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs.push(
      this.factorselectionService.chooseAnother()
    );
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
        sub.unsubscribe();
    });
  }
}
