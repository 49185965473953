import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DisplayService } from '../service/display.service';
import { GlobalService } from '../service/global.service';
import { InitService } from '../service/init.service';
import {TokenService} from "../service/token.service";
import {AuthState, AuthStateService} from "../service/auth-state.service";
import {environment} from "../../environments/environment";

@Injectable()
export class HttpRequestResponseInterceptor implements HttpInterceptor {

  constructor(
    private initService: InitService,
    private displayService: DisplayService,
    private globalService: GlobalService,
    private authStateService: AuthStateService,
    private oAuthInitializationService : TokenService
  ) { }


  intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    let headerObj = {};
    if (request.body) {
      // get flowstate based on action type
      let flowstate = this.displayService.flowState;
      if (flowstate) {
        headerObj['x-flow-state'] = flowstate;
      }
    }
    if (request.body && request.body.noFlowState) {
      delete headerObj['x-flow-state'];
      delete request.body.noFlowState;
    }
    if (request.headers && this.displayService.clientTransactionId) {
      headerObj['X-CLIENT-TRANSACTION-ID'] = this.displayService.clientTransactionId;
    }
    if (this.authStateService.clientAccessToken) {
      if (request.body && request.body.noToken) {
        delete request.body.noToken;
      } else {
        headerObj['Authorization'] = 'Bearer ' + this.authStateService.clientAccessToken;
      }
    }
    if (request.body && request.body.userAccessToken) {
      headerObj['Authorization'] = 'Bearer ' + this.authStateService.userAccessToken;
      delete request.body.userAccessToken;
    }
    if (request.url.includes('/MeCreds')) {
      headerObj['Authorization'] = 'Bearer ' + this.authStateService.userAccessToken;
    }
    // for token
    if (request.body && request.body.basic) {
      headerObj['Authorization'] = request.body.basic;
      delete request.body.basic;
    }
    // for token
    if (request.body && request.body.payloadTypeAttribute) {
      headerObj['Content-Type'] = 'application/x-www-form-urlencoded';
      request.body = request.body.payloadTypeAttribute;
    }
    // Broadcom manually adds Tenant header, we add in in ingress, not necessary
    // if (request.body && request.body.tenantName) {
    //   headerObj["X-TENANT-NAME"] = localStorage.getItem("sspTenant");
    //   delete request.body.tenantName;
    // }
    if (request.body && request.body.deviceTag) {
      headerObj['x-device-tag'] = this.displayService.fingerPrintDna;
      delete request.body.deviceTag;
    }
    if (request.body && request.body.resetFlow) {
      headerObj['x-reset-flow'] = "true";
      headerObj['x-flow-state'] = this.displayService.flowState;
      delete request.body.resetFlow;
    }
    if (request.body && request.body.rememberThisDevice) {
      headerObj['remember-this-device'] = "true";
      delete request.body.rememberThisDevice;
    }
    if (request.body && request.body.sspTDI) {
      headerObj['ssp-tdi'] = request.body.sspTDI;
      delete request.body.sspTDI;
    }
    if (request.body && request.body["LATEST-FLOW-STATE"] === "[LATEST-FLOW-STATE]") {
      headerObj['x-flow-state'] = this.displayService.flowState;
      delete request.body["LATEST-FLOW-STATE"];
    }
    // Add x-flow-state for BrandingSettings call
    if (request.url.includes("BrandingSettings") && this.displayService.flowState) {
      headerObj['x-flow-state'] = this.displayService.flowState;
    }
    if (request.url.endsWith("/idp")) {
      headerObj['x-flow-state'] = this.displayService.flowState;
      headerObj['X-CLIENT-TRANSACTION-ID'] = this.displayService.clientTransactionId;
      if (environment.production) {
        headerObj['idp-relay-state'] = this.displayService.serviceUrl + "portal/idpComplete/";
      } else {
        // On localhost:4200 or local.dev.bnymellon.com
        headerObj['idp-relay-state'] = window.location.origin + "/common/portal/idpComplete/";
      }
    }

    const headers = new HttpHeaders(headerObj);
    request = request.clone({ headers });

    const successHandler = (event: HttpEvent<any>) => {
      this.authStateService.setIdleTimer();
      this.displayService.lastTransaction = new Date();
      if (event instanceof HttpResponse) {
        if (event.body && event.body.flowState) {
          let eventBodyClone = JSON.parse(JSON.stringify(event.body));
          this.displayService.processResponse(eventBodyClone);
        }
      }
    };
    // x-flow: actually because there are two separate access tokens we could be using, we need to figure out which one we are using
    const errorHandler = (error) => {
      this.displayService.lastTransaction = new Date();
      if (error.status === 401 && error.error && (error.error.errorCode === "0000005" || ("" + error.error.errorMessage).toLowerCase() === "invalid access token")) {
        return this.oAuthInitializationService.getClientAccessToken()
          .pipe(switchMap((data) => {
            this.authStateService.clientAccessToken = data["access_token"];
            let newAuthReq = request.clone({
              headers: request.headers
                .set('X-CLIENT-TRANSACTION-ID', this.displayService.clientTransactionId)
                .set("Authorization", "Bearer " + data["access_token"])
            });
            return next.handle(newAuthReq).pipe(tap(successHandler));
          }));
      } else {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Unexpected Error: ${error.error.error}`;
          this.displayService.errorDiv = true;
          this.displayService.errorMsg = errorMsg;
        } else {
          if (error && error.error && error.error.errorMessage) {
            errorMsg = error.error.errorMessage;
          } else if (error && error.error && error.error.error_description) {
            errorMsg = error.error.error_description;
          } else if (error && error.error && error.error.data && error.error.data.errorMessage) {
            errorMsg = error.error.data.errorMessage;
          } else if (error && error.error && error.error.data && error.error.data.message) {
            errorMsg = error.error.data.message;
          }
          this.displayService.errorDiv = true;
          this.displayService.errorMsg = errorMsg;
          if (error && error.error && error.error.errorCode == "0000010"
              && error.error.errorMessage == "Invalid X-Flow-State header") {
            this.authStateService.setAuthStatusRestartNeeded();
          }
        }
        return throwError(error);
      }
    };

    return next.handle(request).pipe(tap(successHandler), catchError(errorHandler));
  }
}
