<div class="main-console-container container-fluid drop-shadow p-md-5" *ngIf="passwordEnabled">
  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Password Login
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">

      <form class="h-100" [formGroup]="passwordForm" novalidate [style.visibility]="ready? 'visible' : 'hidden'">

        <!-- First Flex Element -->
        <div class="mb-2">
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <span>Please enter your password</span>
        </div>

        <!-- Second Flex Element -->
        <div>
          <input id="password-in" type="password" formControlName="password" placeholder="Password" class="form-control user-input"/>
          <div *ngIf="passwordForm.controls['password'].invalid && passwordForm.controls['password'].dirty"
            class="alert alert-danger move-up mb-0">
            <div *ngIf="passwordForm.controls['password'].errors?.required" class="alert-red">
              Password is required.
            </div>
          </div>
        </div>

        <!-- Third Flex Element -->
        <div class="row mt-4">
          <div class="col">
            <button id="submit-btn" [disabled]="passwordForm.controls['password'].invalid" class="primary-btn"
                    (click)="onPasswordSubmit()">
              Submit
            </button>
          </div>
          <div class="col ms-auto" *ngIf="factorSelection">
            <button class="secondary-btn" (mousedown)="factorSelect()">
              More Options
            </button>
          </div>
        </div>

        <!-- Fourth Flex Element -->
        <div>
          <app-userinfo></app-userinfo>
          <app-loading *ngIf="!ready && !errorDiv"></app-loading>
        </div>

      </form>

    </div>
  </div>
</div>
