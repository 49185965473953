<div class="my-3">
  <div class="row g-0 justify-content-evenly " id="otp-field-container" #otpWrapper>

    <input #otpField class="otp-input-box text-center" maxlength="1" [type]="hideEntry ? 'password' : 'number'"
           *ngFor="let digit of allDigitInputs; let index=index;trackBy:trackByIndex;"
           [(ngModel)]="allDigitInputs[index]" [ngStyle]="{'width': fieldWidth + '%'}"
           (keydown)="onKeydown($event, index)" (paste)="onPaste($event, index)"/>
  </div>

  <div *ngIf="otpErrorMsg" class="alert alert-danger move-up mb-0 mt-1">
    <div class="alert-red">
      {{otpErrorMsg}}
    </div>
  </div>
</div>


