<div class="main-console-container container-fluid gx-0">

  <div class="row mb-3">
    <!-- Title -->
    <div class="col col-12">
      <div class="main-console-title h-100 text-center">
        EMEA Cookie Policy Information
      </div>
    </div>

    <!-- Regional Choice -->
    <div class="col-12">
      <!-- EMEA Card - info generated by OneTrust -->
      <div class="main-console-card p-3 h-100">
        <iframe id="iframe-ot-notice" src="./assets/emea-region-iframe.html" class="w-100" scrolling="no">
        </iframe>
        <div>
          <app-loading *ngIf="loading"></app-loading>
        </div>
      </div>
    </div>

  </div>
</div>
