<h2 mat-dialog-title class="nxn-header-separator">Register Authenticator</h2>
<mat-dialog-content>

  <div class="qr-text-padding row text-center">
  Scan the QR code with your mobile authenticator and enter the security code to verify
  </div>

  <div class="row text-center">
  <ngx-qrcode [elementType]="'canvas'" [value]="data.sharedSecret"></ngx-qrcode>
  </div>

  <div class="row text-center">
    <div class="col-8 offset-2 col-xl-6 offset-xl-3">
      <input [formControl]="otpValueControl" type="numeric" class="form-control user-input mb-1" placeholder="Security Code"
           (keydown.enter)="onVerifyOTP()">
    </div>
  </div>

  <div class="row text-center" *ngIf = "errorMsg">
    <div class="col-6 offset-3 col-xl-4 offset-xl-4">
      <span class="alert-red">{{errorMsg}}</span>
    </div>
  </div>

</mat-dialog-content>

<div class="col-8 offset-2">
<mat-dialog-actions align="center">
    <button mat-button name="verifyBtn" class="registerBtnWdth dialog-primary-btn" cdkFocusInitial (click)="onVerifyOTP()" [disabled]="otpValueControl.invalid || submitted">Verify Code</button>
    <button mat-button name="cancelBtn" class="registerBtnWdth dialog-secondary-btn" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
</div>




