<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      Identity Provider
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto flex-space">
      <div>
        <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
        <span *ngIf="!errorDiv">Connecting to {{idpService.idpFriendlyName? idpService.idpFriendlyName : "Identity Provider"}}</span>
      </div>

      <div class="row mt-3" *ngIf="factorSelection && errorDiv">
        <div class="col">
          <button class="secondary-btn" (mousedown)="chooseAnother()">More Options</button>
        </div>
      </div>
      <div class="my-4">
        <mat-progress-bar *ngIf="!errorDiv" mode='indeterminate'></mat-progress-bar>
      </div>
      <div>
        <app-userinfo></app-userinfo>
      </div>

    </div>
  </div>
</div>
