<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">

    <div id="welcome-user" class="main-console-title mx-auto mb-md-3">
      Hello, {{userName}}
    </div>

    <div class="main-console-card do-not-flex text-justify mx-auto">
      Here you can access the VIP Authentication Hub self service tools.
      <div class="row my-3">
        <div class="col-md-6 mb-2 m-md-0">
          <a class="reference" routerLink="/self-service">
            Manage Credentials
          </a>
        </div>
      </div>
    </div>

  </div>

</div>
