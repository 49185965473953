<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">

    <div class="main-console-title mx-auto mb-md-3">
      Your Credentials
    </div>

    <div class="main-console-card flex-space text-justify mx-auto" *ngIf="waitingForPush || waitingForBio || isLoading">
      <h6 *ngIf="waitingForPush">Please accept the push notification on your device</h6>
      <h6 *ngIf="waitingForBio">Please complete the device registration</h6>
      <app-loading class="py-3"></app-loading>
      <app-userinfo *ngIf="!isLoading"></app-userinfo>
    </div>

    <div class="main-console-card flex-space text-justify mx-auto" *ngIf="!waitingForPush && !waitingForBio && !isLoading">

      <div class="row row-marg-bottom px-2 text-center justify-content-center">
        <div class="col-md-3 mb-1">
          <button class="primary-btn align-bottom container-fluid mb-2" (click)="getCreds()">
            <fa-icon [icon]="refreshIcon" class="pe-2 text-white" title="Refresh Icon"></fa-icon>
            Refresh
          </button>
        </div>
        <div class="col-md-3 mb-1" *ngIf="credentialsList.length">
          <button class="primary-btn align-bottom container-fluid mb-2" (click)="handleDeleteAll()">
            <fa-icon [icon]="deleteIcon" class="pe-2 text-white" title="Delete Icon"></fa-icon>
            Delete All
          </button>
        </div>
        <div class="col-md-3 mb-1">
          <button class="primary-btn align-bottom container-fluid mb-2" routerLink="/welcome">
            <fa-icon [icon]="homeIcon" class="pe-2 text-white" title="Home Icon"></fa-icon>
            Home
          </button>
        </div>
      </div>

      <div class="row row-marg-bottom px-2 text-center justify-content-center" *ngIf="!credentialsList.length">
        <h4>No credentials associated to your account.</h4>
      </div>

      <div class="table-responsive" *ngIf="credentialsList.length">
        <table class="table table-striped self-service-content">
          <tbody>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Details</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center">
              Operations
            </th>
          </tr>
          <tr *ngFor="let item of credentialsList; let i = index"
              [ngClass]="{'cred-row':true,'last-cred-row':i===credentialsList.length-1}"
              [attr.data-cred-id]="item.credId">
            <td>
              {{(credTypeMap[item?.credType] || {}).name || item?.credType}}
            </td>
            <td>
              {{item?.credValue}}
              <br/>
              <span
                class="addedTS">Added {{getCustomDateFormat(item?.createdDatetime || item?.modifiedDatetime)}}</span>
            </td>
            <td>
              <div class="row">
                <select class="self-service-content col rounded form-select" *ngIf="statusMap[item.status]" [ngModel]="item.status"
                        (change)="onStatusChange(item, $event.target.value)">
                  <option *ngFor="let status of statuses" [value]="status.value">{{status.label}}</option>
                </select>
                <span class="col statusText" *ngIf="!statusMap[item.status]">{{('' + item.status).toLowerCase()}}</span>
              </div>
            </td>
            <td class="text-center">
              <button class="table-btn px-3" (click)="handleDeleteCred(item)">
                <i class="fas fa-trash-alt" alt="Delete "></i>
                <fa-icon [icon]="deleteIcon" class="text-white" title="Delete Icon"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>


      <div class="row row-marg-top row-marg-bottom">
        <div class="col-md-6 ms-2">
          <div class="container">
            <div class="radio-container row row-marg-bottom">
              <h6>
                Register New Credential
              </h6>
            </div>
            <div class="radio-container row row-marg-bottom">
              <label>
                <input (change)="credTypeChange()" (click)="focusRegInputField()" [(ngModel)]="selectedCredType"
                       type="radio" name="credType" value="email">
                Email
              </label>
            </div>
            <div class="radio-container row row-marg-bottom">
              <label>
                <input (change)="credTypeChange()" (click)="focusRegInputField()" [(ngModel)]="selectedCredType"
                       type="radio" name="credType" value="sms">
                SMS
              </label>
            </div>
            <div class="radio-container row row-marg-bottom">
              <label>
                <input (change)="credTypeChange()" (click)="focusRegInputField()" [(ngModel)]="selectedCredType"
                       type="radio" name="credType" value="fido">
                Biometric
              </label>
            </div>
            <div class="radio-container row row-marg-bottom">
              <label>
                <input (change)="credTypeChange()" (click)="focusRegInputField()" [(ngModel)]="selectedCredType"
                       type="radio" name="credType" value="securitykey">
                Security key
              </label>
            </div>
            <div class="radio-container row row-marg-bottom">
              <label>
                <input (change)="credTypeChange()" (click)="focusRegInputField()" [(ngModel)]="selectedCredType"
                       type="radio" name="credType" value="totp_push">
                VIP Access
              </label>
            </div>
            <div class="radio-container row row-marg-bottom">
              <label>
                <input (change)="credTypeChange()" (click)="focusRegInputField()" [(ngModel)]="selectedCredType"
                       type="radio" name="credType" value="totp">
                Code from Mobile Authenticator
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-5 ms-2">
          <div class="register-container container">
            <div class="input-container">
              <div class="row row-marg-bottom">
                <div class="col mb-1">
                  <app-errormsg [errorDiv]="showError" [errorMsg]="errorMessage"></app-errormsg>
                  <p class="submission-help-font">Enter {{credTypeMap[selectedCredType].label}}</p>
                  <input class="regInputField form-control user-input" [formControl]="credValueControl" type="text"
                         (focus)="showError = false">
                  <div *ngIf="selectedCredType === 'email'" class="tos-notice-container rounded mt-3 p-2" color="black">
                    <fa-icon [icon]="infoIcon" class="me-2" title="Info Sign"></fa-icon>
                    <span>By providing your email address, you agree to receive emails from "BNY Mellon Corp"</span>
                  </div>
                  <div *ngIf="selectedCredType === 'sms'" class="tos-notice-container rounded mt-3 p-2" color="black">
                    <fa-icon [icon]="infoIcon" class="me-2" title="Info Sign"></fa-icon>
                    <span>By providing your phone number, you agree to receive text messages from "BNY Mellon Corp". Message and data rates may apply. Message frequency varies.</span>
                  </div>
                  <p *ngIf="selectedCredType === 'totp_push'" class="submission-help-font">Enter your VIP Access Credential ID</p>
                  <input *ngIf="selectedCredType === 'totp_push'" class="regInputField form-control user-input" [formControl]="vipCredentialControl" type="text"
                         (keydown.enter)="onRegister()">
                </div>

              </div>
              <div class="row row-marg-bottom">
                <div class="col">
                  <button class="primary-btn align-bottom container-fluid mb-2 cred-submission-btn"
                          [disabled]="credValueControl.pristine || (vipCredentialControl.pristine && selectedCredType === 'totp_push') || regBtnClicked" (click)="onRegister()">
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

</div>
