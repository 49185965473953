import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {concatMap, map, Observable, of, Subscription, timer} from "rxjs";
import {FactorselectionService} from "../service/factorselection.service";
import {Router} from "@angular/router";
import {LdapService} from "../service/ldap.service";
import {DisplayService} from "../service/display.service";
import {ChangePasswordService} from "../service/change-password.service";

/**
 * This component allows the user to update their password when it is expired. The page contains a
 * reactive-form with three input fields:  current password, new password, and confirm password.
 *
 * Each input is live-validated and marked with the appropriate errors. There is no consensus for how
 * live-validation should be handled, but the different philosophies converge towards the same goal.
 * It can be summarized simply as: "check the validity of input and give feedback before submission"
 * ({@link https://coyleandrew.medium.com/forms-need-validation-2ecbccbacea1#e661 Source}).
 *
 * The form inputs use the following validations:
 * - Each input is required. (Control-level validation)
 * - The current password and new password values must not match. (Group-level validation)
 * - The new password and confirm password values must match. (Group-level validation)
 *
 * This form uses the following approach for live-validation.
 * - Only update the input validity when the user is done with a particular field. This is indicated by
 *   the 'blur' event, which fires when an element loses focus. This prevents displaying temporary
 *   validation issues while the user is still working, and provides a consistent user experience.
 * - Initially hide error messages for an empty input field. Only reveal this after modification or form
 *   submission.
 * - Allow user to click submit button at any time. If the form is valid, then send the request.
 *   Otherwise, display all errors, including hidden like field-required.
 */
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit, OnDestroy  {
  errorMsg: String;
  errorDiv: boolean = false;
  userName = null;
  passwordChangeForm: FormGroup;
  factorSelection: boolean = false;
  ready: boolean = true;
  pageSubs: Subscription[] = new Array<Subscription>();
  currPwdCtrl: FormControl;
  newPwdCtrl: FormControl;
  confirmNewPwdCtrl: FormControl;

  constructor(private factorselectionService: FactorselectionService,
              private router: Router,
              private ldapService: LdapService,
              private displayService: DisplayService,
              private changePasswordService: ChangePasswordService) {


  }

  ngOnInit() {
    if (this.displayService.userName == null) {
      this.router.navigate(["../"]);
    }
    this.initForms();
  }

  private initForms() {
    /*
       Set control-level validators for the input fields, simply to ensure they exist
    */
    this.currPwdCtrl = new FormControl("", {
      validators: [Validators.required, Validators.minLength(1)],
      updateOn: 'blur'
    });
    this.newPwdCtrl = new FormControl("", {
      validators: [Validators.required, Validators.minLength(1)],
      updateOn: 'blur'
    });

    this.confirmNewPwdCtrl = new FormControl("", {
      validators: [Validators.required, Validators.minLength(1)],
      updateOn: "blur"
    });

    /*
        Set group-level validators to compare the input fields
    */
    this.passwordChangeForm = new FormGroup(
      {
        currentPassword: this.currPwdCtrl,
        newPassword: this.newPwdCtrl,
        confirmNewPassword: this.confirmNewPwdCtrl
      },
      {
        validators:  [this.reusePasswordValidator(), this.confirmNewPasswordValidator()],
        updateOn: 'blur'
      }
    );
  }

  /**
   * Validate the new and current password are not the same.
   */
  reusePasswordValidator(): ValidatorFn | null {
    return () : ValidationErrors | null => {
      if (this.currPwdCtrl?.value && this.newPwdCtrl?.value && this.currPwdCtrl?.value == this.newPwdCtrl?.value) {
        return {reuseOld: true};
      } else {
        return null;
      }
    }
  }

  /**
   * Validate the new password and confirm password values match.
   */
  confirmNewPasswordValidator(): ValidatorFn | null {
    return () : ValidationErrors | null => {
      if (!(this.isPasswordMatch() || this.confirmNewPwdCtrl?.value === "")) {
        return {passwordMismatch: true};
      } else {
        return null;
      }
    }
  }

  private isPasswordMatch() {
    return this.newPwdCtrl?.value === this.confirmNewPwdCtrl?.value;
  }


  factorSelect() {
    this.errorDiv = false;
    this.ready = false;
    this.factorselectionService.chooseAnother();
  }

  ngOnDestroy() {
    this.pageSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * Form submission. Allow the user to submit method at any time. Only submit password when the form is valid. If the
   * form is invalid, then display any hidden error messages (e.g. fields are required).
   */
  onFormSubmit() {
    if (this.passwordChangeForm.valid) {
      this.onPasswordSubmit();
    } else {
      this.currPwdCtrl.markAsDirty();
      this.newPwdCtrl.markAsDirty();
      this.confirmNewPwdCtrl.markAsDirty();
    }
  }

  private onPasswordSubmit() {
    this.errorDiv = false;
    this.ready = false;
    this.pageSubs = [this.changePasswordService.onPasswordChange(this.passwordChangeForm.value.currentPassword,
      this.passwordChangeForm.value.newPassword)
      .subscribe({
        next: (response) => {
          if (response?.['data']?.["passwordUpdated"]) {
            this.displayService.routeActions(response);
          } else {
            this.errorMsg = "Password update failed!!";
            this.errorDiv = true;
          }
        },
        error: (err) => {
          this.errorDiv = this.displayService.errorDiv;
          this.errorMsg = this.displayService.errorMsg;
          this.errorDiv = true;
          this.ready = true;
          this.passwordChangeForm.reset();
        }
      })
    ];
  }

  nextField(n: number) {
    this.displayService.focusNthField(n);
  }
}
