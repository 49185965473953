import {Component} from '@angular/core';
import {DisplayService} from "./service/display.service";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Subscription} from "rxjs";
import {AuthState, AuthStateService} from "./service/auth-state.service";
import {ResetService} from "./service/reset.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

  isAuthenticated: boolean;
  logoutButton = faSignOutAlt;
  private authStatusSource: Subscription;

  constructor(    private displayService: DisplayService,
                  private authStateService: AuthStateService,
                  private resetService: ResetService)
  {
    // Subscribe to user auth status
    this.authStatusSource = authStateService.getAuthStatusSource()
      .subscribe((authStatus) => {
        this.isAuthenticated = (authStatus === AuthState.AUTHENTICATED);

        if (authStatus === AuthState.NONE) {
          this.displayService.flowState = null;
          this.displayService.userName = null;
        }
      });
  }

  doLogout() {
    this.resetService.clearQueryParams();
    this.authStateService.doLogout();
  }
}
