<div class="main-console-container container-fluid drop-shadow p-md-5">

  <div class="row">
    <div class="main-console-title col-md-5 text-justify">
      A Better Login Experience
    </div>

    <div class="main-console-card col-md-6 text-justify ms-auto">


      <form class="h-100"  [formGroup]="loginForm" novalidate [style.visibility]="ready?  'visible' : 'hidden'">

        <div>
          <app-errormsg [errorDiv]="errorDiv" [errorMsg]="errorMsg"></app-errormsg>
          <span>Please enter user name</span>
        </div>

        <div>
          <input id="username-in" type="text" formControlName="userName" placeholder="User Name" class="form-control user-input mt-1">

          <label class="checkbox-label row mt-1 mb-4" for="rememberMe">
            <mat-checkbox disableRipple value="true"[checked]="true" id="rememberMe" name="rememberMe" formControlName="rememberMe">
              Remember Me
              <img src="assets/img/help-icon.svg" title="Your username and device will be saved for future logins." alt="Remember me"
                   class="tooltip-icon"/>
            </mat-checkbox>
          </label>
        </div>

        <button id="submit-btn" type="submit" [disabled]="loginForm.controls['userName'].invalid" (click)="onLogin()"
                class="primary-btn container-fluid m-0">
          <img src="assets/img/favicon.png" class="white-bnym-logo"/>
          <span>Login</span>
        </button>

        <div>
          <app-userinfo *ngIf="!ready && userName && !initialSubmit" (resetEmitter)="resetUser($event)"></app-userinfo>
          <app-loading *ngIf="!ready && !errorDiv"></app-loading>
        </div>

      </form>

    </div>
  </div>
</div>

<div class="warning-container container-fluid drop-shadow mt-4 p-1" color="black">
  <fa-icon [icon]="warningIcon" class="px-2" title="Warning Sign"></fa-icon>
  <span>This system is for authorized users only. We may monitor, record, and disclose all activity and information on this system, subject to local law.</span>
</div>

