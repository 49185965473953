import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AppComponent } from './app.component';
import { EmailotpComponent } from './emailotp/emailotp.component';
import { FactorselectionComponent } from './factorselection/factorselection.component';
import { FidoComponent } from './fido/fido.component';
import { InitComponent } from './init/init.component';
import { HttpRequestResponseInterceptor } from './interceptor/http-request-response.interceptor';
import { LdapComponent } from './ldap/ldap.component';
import { MotpComponent } from './motp/motp.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PushComponent } from './push/push.component';
import { MultiCredentialsComponent } from './multi-credentials/multi-credentials.component';
import { SelfServiceComponent } from './self-service/self-service.component';
import { SotpComponent } from './sotp/sotp.component';
import { UserinfoComponent } from './userinfo/userinfo.component';
import { WelcomeComponent } from './welcome/welcome.component';

import {CommonModule} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {ConfirmationDialogComponent} from "./shared/confirmation-dialog.component";
import {HomeComponent} from "./home/home.component";
import { SecuridComponent } from './securid/securid.component';
import { DeniedComponent } from './denied/denied.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { TroubleshootComponent } from './troubleshoot/troubleshoot.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ErrormsgComponent } from './errormsg/errormsg.component';
import { LoadingComponent } from './loading/loading.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { CookiesPolicyComponent } from './cookies-policy/cookies-policy.component';
import { OtpInputComponent } from './otp-input/otp-input.component';
import {RegisterOAthComponent} from "./self-service/register-oath/register-oath-component";
import {RegisterEmailOrSmsComponent} from "./self-service/register-email-sms/register-email-sms-component";
import { InfoPageComponent } from "./info-page/info-page.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { IdpComponent } from './idp/idp.component';
import { IdpCompleteComponent } from './idp-complete/idp-complete.component';
import { NeedRestartComponent } from './need-restart/need-restart.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { SmsTosComponent } from './sms-tos/sms-tos.component';
import {EnvService} from "./service/env.service";
import {DisplayService} from "./service/display.service";
import {EnvApiData} from "./service/EnvApiData";
import {InitService} from "./service/init.service";
import {tap} from "rxjs/operators";
import { EmeaCookiesComponent } from './emea-cookies/emea-cookies.component';
import { VoiceotpComponent } from './voiceotp/voiceotp.component';
import { OtpGeneralComponent } from './otp-general/otp-general.component';

function initializeEnvironmentFactory(envService: EnvService, displayService: DisplayService, initService: InitService) {
  return () => envService.getEnvJson().pipe(
    tap(authData => {
      envService.authData = authData as EnvApiData;
      initService.changeRiskType(authData.riskType)
      displayService.logoutUrl = authData.logoutUrl;
      localStorage.setItem('logoutUrl', displayService.logoutUrl);
      displayService.serviceUrl = authData.baseServiceUrl;
      displayService.proxyUrl = envService.getProxyURL(authData);
    })
  );
}

@NgModule({
  declarations: [
    AppComponent,
    InitComponent,
    WelcomeComponent,
    LdapComponent,
    UserinfoComponent,
    FactorselectionComponent,
    SotpComponent,
    EmailotpComponent,
    MultiCredentialsComponent,
    FidoComponent,
    SelfServiceComponent,
    RegisterOAthComponent,
    RegisterEmailOrSmsComponent,
    MotpComponent,
    PushComponent,
    ConfirmationDialogComponent,
    NotFoundComponent,
    HomeComponent,
    SecuridComponent,
    DeniedComponent,
    TroubleshootComponent,
    ErrormsgComponent,
    LoadingComponent,
    HelpPageComponent,
    CookiesPolicyComponent,
    OtpInputComponent,
    InfoPageComponent,
    LogoutPageComponent,
    IdpComponent,
    IdpCompleteComponent,
    NeedRestartComponent,
    ChangePasswordComponent,
    PasswordInputComponent,
    SmsTosComponent,
    EmeaCookiesComponent,
    VoiceotpComponent,
    OtpGeneralComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgxQRCodeModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    ClipboardModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestResponseInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeEnvironmentFactory,
      multi: true,
      deps: [EnvService, DisplayService, InitService],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
