import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class FidoregService {

  constructor(private http: HttpClient, private displayService: DisplayService) {
  }

  public activationGenerateChallenge(name, device, type) {
    const postData = {
      userName: name,
      deviceName: device,
      documentDomain: location.origin,
      fidoCredType: type,
      userAccessToken: true,
      noFlowState: true
    };

    return this.http.post(this.displayService.serviceUrl + 'factor/v1/FIDORegChallengeGenerator', postData);
  }

  public sendRegistrationVerifyChallenge(makeCredReq, type) {
    makeCredReq["fidoCredType"] = type;
    makeCredReq.userAccessToken = true;
    makeCredReq.noFlowState = true;
    return this.http.post(this.displayService.serviceUrl + 'factor/v1/FIDORegChallengeVerifier', makeCredReq);
  }
}
